import React from "react";

// Customizable Area Start
import {
  Grid,
  Divider,
  Typography,
  Modal,
  Backdrop,
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Button,
 OutlinedInput, InputAdornment
} from '@material-ui/core'
import { styled } from '@material-ui/styles'
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import { Formik } from 'formik'

import FormModal, {
  InputRow,
  MInput,
  MCountedTextArea,
  MFileDrop,
  MTimePicker,
  MDatePicker,
} from '../../../components/src/FormModal.web'
import { FRating } from '../../../components/src/FormModal.web'



import SeeMore from '../../../components/src/SeeMore.web'
import { Link } from 'react-router-dom';
import LikeAPost from '../../LikeAPost/src/LikeAPost.web'

import MediaCarousel from '../../../components/src/MediaCarousel.web'
import UserContext from '../../../components/src/UserProvider'


import Picker from 'emoji-picker-react';

import Flex from '../../../components/src/Flex.web'
import Rating from '../../../components/src/Rating.web'
import  { CommonActions } from '../../../components/src/PostCard.web'
import DiscountCard from '../../../components/src/DiscountCard.web'
import {  OyoAvatar, SendIcon, EmogiIcon, LikedIcon, CloseIcon, iconCorp, LogoHex } from './assets'

//import ReportModal from '../../../components/src/ReportModal.web'

// Customizable Area End

import CourseDetailController, {
  Props,
  configJSON
} from "./ProductDetailsController";
export default class CourseDetails extends CourseDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  rateProductModal = () => {
    const product = this.state.productData
    if (this.state.modal !== 'rate') return <></>
    return <Formik initialValues={{}} onSubmit={this.handleRateProductSubmit}>
      <FormModal
        modalStyle={{ height: '360px' }}
        headerTitle="RATE PRODUCT"
        submitLabel="Submit Review"
        onClose={this.hideModal}>
        <Flex py="25px" gap="10px" col align="center">
          <Typography style={{ wordBreak: 'break-all' }} variant="h5">{product?.product_name}</Typography>
          <Typography>Product ID: {product?.product_unique_id}</Typography>
          <Typography color="textSecondary" variant="h5">Rate your product!</Typography>
          <FRating value={this.state.modalRating} onChange={this.setModalRating} />
        </Flex>
      </FormModal>
    </Formik>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const rate = this.rateProductModal()
    const { navigation } = this.props
    let { productData, productCommentData } = this.state
    console.log("productData ", productData);
    console.log("productData1 ", productCommentData);
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.state.modal == "rate" ? rate :
          <Wrapper>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: '20px 20px',
              }}
              open={this.state.openModal}
              //onClose={}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >

              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  background: '#fff',
                  borderRadius: 8,
                  width: '700px',
                  minWidth: "280px",
                  position: "relative",
                }} className="schedule_modal">
                <Grid container spacing={0}>
                  <ModelBody item xs={12}>
                    <RoundedCard style={{ padding: '0', margin: '0', maxHeight: 'calc(100vh - 50px)', overflow: 'scroll' }} key={productData?.id} variant="outlined">
                      <CardHeader
                        style={{ padding: '25px 20px 15px 10px' }}
                        avatar={<Link to={`user/${productData?.created_by?.id}`}><Avatar style={{ width: 55.26, height: 51.87 }} src={productData?.created_by?.profile_pic || OyoAvatar} onClick={() => navigation.navigate(`user/${productData?.created_by?.id}`)} /></Link>}
                        title={<span style={{ fontWeight: 600, marginBottom: '4px', fontSize: "1.021rem", maxWidth: '30ch', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block', textTransform: 'capitalize' }}><Link to={`user/${productData?.created_by?.id}`}>{productData.created_by?.name}</Link></span>}
                        subheader={<Grid style={{ gap: '10px' }} container alignItems="center">
                          <span style={{ textTransform: 'capitalize', fontSize: '0.75rem' }}>{productData.created_by?.location}</span>
                          <Divider orientation="vertical" flexItem />
                          <span style={{ fontSize: '0.75rem' }}>{productData.created_at?.replaceAll('about', '')}</span>
                        </Grid>}
                      />
                      <CloseButton data-test-id="CloseButton"onClick={this.onCloseModal}>
                        <img src={CloseIcon} width="26px" />
                      </CloseButton>
                      <Divider />
                      <ProductWrapper style={{ color: '#222', display: 'flex', }}>
                        <Flex col f={3}>
                          <MediaCarousel items={productData.images || []} />
                        </Flex>
                        <Flex gap="10px" col f={5} style={{ padding: '10px 0px 10px' }}>
                          <Typography style={{ wordBreak: 'break-all', fontSize: '1.5rem', fontWeight: 600 }} variant="h4">
                            {productData?.product_name?.length > 35 ? `${productData?.product_name.substring(0, 35)}...` : productData?.product_name}
                          </Typography>
                          <Flex gap="20px" align="center">
                            <Typography style={{ marginTop: '8px', color: '#1da509', fontWeight: 'bold', fontSize: '18px' }}>${productData.price?.usd} (₹{productData.price?.inr})</Typography>
                            <div style={{ transform: 'scale(0.7)' }}>
                              <Rating disabled={true} total={5} value={productData.average_rating} />
                            </div>
                          </Flex>
                          <CPWrapper>
                            <div><b>Category:</b> {productData?.categories}</div>
                            <div><b>Product ID:</b> {productData?.product_unique_id}</div>
                          </CPWrapper>
                          <div>
                            <SeeMore limit={100} text={productData.description} />
                          </div>
                          <Flex style={{ margin: '15px 0px', width: '350px' }}>
                            {productData.coin_discount > 0 && <DiscountCard discount={productData.coin_discount} coins={productData.equivalent_coins} />}
                          </Flex>
                          {/* <Flex style={{padding: '6px 0px'}}>
                    Website Link: <span style={{paddingLeft: '15px', color: '#4673a7'}}><a href={p.website_link}>{p.website_link}</a></span>
                  </Flex> */}

                        </Flex>
                      </ProductWrapper>
                      <Flex px="20px" gap="20px">
                        <Flex col style={{ margin: '0px 0px 20px 0px', width: "35.1%" }}>
                          {
                            productData.is_rated ?
                              <RatedButton disabled data-id={productData.id}
                                onClick={this.onRateClick}
                              >Rate this Product</RatedButton>
                              :
                              <RateButton data-id={productData.id}
                                onClick={this.onRateClick}
                              >Rate this Product</RateButton>
                          }

                        </Flex>
                        <Flex style={{ padding: '13px 0px', marginLeft: "1rem", flexWrap: 'wrap', alignItems: 'center' }}>
                          Website Link:
                          <span style={{ paddingLeft: '10px', color: '#48a15b' }}>
                            <a href={productData.website_link} target="_blank">
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <img src={LogoHex} width="25px" />
                                <InsertLinkIcon style={{ color: 'inherit', marginTop: '-16px', marginLeft: '8px' }} />
                              </div>
                            </a>
                          </span>
                        </Flex>
                      </Flex>
                      <Divider />
                      <CardContent>
                        <CommonActions
                          onStarClick={this.handleFavoriteClick}
                          onShareClick={this.handleShareCourse(productData)}
                          isFavourite={productData?.is_favourite}
                          isHideComment={true}
                          likeButton={
                            <LikeAPost
                              navigation={this.props.navigation}
                              id=""
                              likeable_type="BxBlockCfproductlisting11::Product"
                              isLiked={productData?.is_liked}
                              post_id={String(productData?.id)}
                              like_id={productData?.like_id ? productData?.like_id[0]?.id : ''}
                              onLikeChange={this.onLikeChange}
                              onLikeIdUpdate={this.onLikeIdUpdate}
                            />}
                        />
                      </CardContent>

                      <DiscussSection>
                        <DiscussHeader>Discussion</DiscussHeader>
                        <div style={{ marginBottom: '40px' }}>
                          <CommentUserAvtar>
                            <Link to={`/user/${this.context?.id}`}><Avatar style={{ width: 35.26, height: 31.87 }} src={this.context?.profile_pic || OyoAvatar} /></Link>
                          </CommentUserAvtar>
                          <div style={{ width: 'calc(100% - 50px)', display: 'inline-block', boxSizing: 'border-box' }}>

                            <OutlinedInput
                              id="outlined-adornment-weight"
                              value={this.state.userComment}
                              onChange={this.handleProductCommentChange}
                              placeholder="Comment your views"
                              endAdornment={<InputAdornment position="end"><BtnImg src={EmogiIcon} onClick={this.openEmojiComment} style={{ width: '18px', paddingLeft: '10px', paddingRight: '10px' }} />
                                <Divider orientation="vertical" style={{ height: '55px', color: '#fefefe' }} />
                                {this.state.userComment.length > 0 ?
                                  <BtnImg src={SendIcon} onClick={() => this.handleProductComment(productData.id)} style={{ width: '18px', paddingLeft: '25px', float: 'right', paddingRight: '2px', cursor: 'pointer' }} />
                                  : <BtnImg src={SendIcon} style={{ width: '18px', paddingLeft: '25px', float: 'right', paddingRight: '2px', opacity: 0.5 }} />
                                }</InputAdornment>}
                              aria-describedby="outlined-weight-helper-text"
                              inputProps={{
                                'aria-label': 'weight',
                              }}
                              style={{ width: '100%' }}
                            />
                          </div>
                          {this.state.openCommentEmoji ?
                            <Picker onEmojiClick={this.onCommentEmojiClick} pickerStyle={{ width: '100%' }} /> : ""}

                        </div>
                        {productCommentData?.map?.(p => <div style={{ marginBottom: '15px' }}>
                          <UserAvtar>
                            <Link to={`/user/${p?.account_id}`}><Avatar style={{ width: 35.26, height: 31.87 }} src={p?.profile_pic || OyoAvatar} onClick={() => navigation.navigate(`/user/${p?.account_id}`)} /></Link>
                          </UserAvtar>
                          <CommentDetail>
                            <AccountName>{p?.account_name}</AccountName>
                            <CommentData>{p?.content}</CommentData>
                          </CommentDetail>
                          <CardActions>
                            <FlexSpaceBetween style={{ padding: '0px 0px 5px 5px' }}>
                              <CardButtonWrapper>
                                <CardButton onClick={() => this.handleCommentLike(p)}>{!p.is_like ? "Like" : <div style={{ color: '#009c05' }}>Like</div>}</CardButton>
                                <div style={{ lineHeight: '2.5', color: '#d4d4d4' }}>|</div>
                                <CardButton onClick={() => this.handleSetReplyId(p.id)}>Reply</CardButton>
                              </CardButtonWrapper>
                              {p?.like_count ?
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <BtnImg src={LikedIcon} style={{ width: '18px' }} />
                                  <span style={{ paddingLeft: '3px', fontSize: '13px' }}>{p?.like_count}</span>
                                </div> : ''}
                            </FlexSpaceBetween>
                          </CardActions>
                          {this.state.reply_id === p.id ?
                            <div style={{ marginTop: '15px', marginBottom: '40px', paddingLeft: '50px' }}>
                              <CommentUserAvtar>
                                <Link to={`/user/${this.context?.id}`}><Avatar style={{ width: 35.26, height: 31.87 }} src={this.context?.profile_pic || OyoAvatar} /></Link>
                              </CommentUserAvtar>
                              <div style={{ width: 'calc(100% - 50px)', display: 'inline-block', boxSizing: 'border-box' }}>

                                <OutlinedInput
                                  id="outlined-adornment-weight"
                                  value={this.state.userReply}
                                  onChange={this.handleProductReplyChange}
                                  placeholder="Comment your views"
                                  endAdornment={<InputAdornment position="end"><BtnImg src={EmogiIcon} onClick={this.openEmojiCommentReply} style={{ width: '18px', paddingLeft: '10px' }} />
                                    {this.state.userReply.length > 0 ?
                                      <BtnImg src={SendIcon} onClick={() => this.handleReplyComment()} style={{ width: '18px', paddingLeft: '25px', float: 'right', paddingRight: '2px', cursor: 'pointer' }} />
                                      : <BtnImg src={SendIcon} style={{ width: '18px', paddingLeft: '25px', float: 'right', paddingRight: '2px', opacity: 0.5 }} />
                                    }</InputAdornment>}
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    'aria-label': 'weight',
                                  }}
                                  style={{ width: '100%' }}
                                />
                              </div>
                              {this.state.openCommentReplyEmoji ?
                                <Picker onEmojiClick={this.onReplyEmojiClick} pickerStyle={{ width: '100%' }} /> : ""}
                            </div> : ''}
                          {p?.replies?.length > 0 && this.state.openReply != p.id ?
                            <div data-test-id = "viewCommentReplies" onClick={() => this.showReply(p.id)} style={{ width: 'calc(100% - 50px)', height: '8px', margin: '0px 50px 25px', borderBottom: '1px solid #cdcdcd', textAlign: 'center' }}>
                              <span style={{ fontSize: '13px', backgroundColor: '#fff', padding: '0 30px', color: '#a9a9a9' }}>
                                View  {p?.replies?.length} replies
                              </span>
                            </div>
                            : ''}
                          {this.state.openReply == p.id ? p?.replies?.map?.((r: any) => <div style={{ padding: '10px 0px 10px 50px' }}>
                            <UserAvtar>
                              <Link to={`/user/${r?.account_id}`}><Avatar style={{ width: 35.26, height: 31.87 }} src={r?.profile_pic || OyoAvatar} onClick={() => navigation.navigate(`/user/${r?.account_id}`)} /></Link>
                            </UserAvtar>
                            <CommentDetail>
                              <AccountName>{r?.account_name}</AccountName>
                              <CommentData>{r?.content}</CommentData>
                            </CommentDetail>
                          </div>
                          ) : ''}
                        </div>)}

                        {
                          this.state.pagination?.next_page ? <div style={{ marginTop: '50px', marginBottom: '50px', width: '100%', textAlign: 'center', color: '#b9b9b9', fontSize: '15px', fontWeight: 'bold', cursor: 'pointer', backgroundColor: '#f0f0f0', padding: '15px 0px', borderRadius: '10px', borderColor: '#fefefe' }} onClick={this.loadMore}>Load more discussion</div> : ''
                        }
                      </DiscussSection>
                    </RoundedCard>
                  </ModelBody>

                </Grid>
              </Box>
            </Modal >
          </Wrapper>
        }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
CourseDetailController.contextType = UserContext

const EarnCoins = ({ coins }: { coins: number }) => <GreenBg>
  <img src={iconCorp} width='35px' />
  <Typography variant="h6" style={{ fontWeight: 600 }} color="primary">Earn {coins} Corpstage coins</Typography>
</GreenBg>

const GreenBg = styled('div')({
  padding: '15px',
  background: '#F2F7E4',
  borderRadius: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '25px'
})

const ModelBody = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'unset',
  flexDirection: 'column',
  padding: '0px'
})

const ProductWrapper = styled('div')({
  color: '#222',
  display: 'flex',
  flexWrap: 'wrap',
  gap: "15px",
  padding: "20px 10px",
  '& .carousel': {
    minWidth: "200px",
  },
  // '@media screen and (max-width: 768px)': {
  //   display: 'flex',
  //   flexWrap: 'wrap',
  //   '& .carousel': {
  //     minWidth: "200px",
  //   }
  // }
  // px="20px" py="10px" gap="15px"
})

const CPWrapper = styled(Flex)({
  margin: '15px 0px',
  gap: "20px",
  '@media screen and (max-width: 480px)': {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  }
})

const ActionRoundedButton = styled(Button)({
  color: '#2caa38',
  border: '2px solid #79bb74',
  borderRadius: '25px',
  padding: '5px 35px',
  textTransform: 'none',
  fontWeight: 600
})


const SelectItemUserText = styled('span')({
  overflow: 'hidden',
  textOverflow: 'ellipsis'
})

const RoundedCard = styled(Card)({
  borderRadius: '15px',
  padding: '10px 20px',
  margin: '20px 0px'
})

const FlexSpaceBetween = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
  padding: '20px 0px',
  width: '100%',
})

const CardButton = styled(Button)({
  border: 'none',
  color: '#aaaaaa',
  fontWeight: 600,
  textTransform: 'none'
})

const CardButtonWrapper = styled('div')({
  display: 'flex',
  gap: '5px',
  padding: '0 20px'
})

const MR5 = styled('span')({
  marginRight: '5px',
  color: '#2a353f',
  textTransform: 'capitalize'
})
const BtnImg = styled('img')({
  width: 25
})
const PostMainContent = styled('div')({
  padding: '10px 0'
})
const CloseButton = styled(Button)({
  position: 'absolute',
  top: '20px',
  right: '0px',
  flex: 1,
})
const Wrapper = styled('div')({
  zIndex: 1010,
  minWidth: '100vw',
  minHeight: '100vh',
  background: 'rgba(0,0,0,0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const DiscussSection = styled('div')({
  padding: '16px 30px'
})

const RatedButton = styled('button')({
  background: '#ececec',
  border: '2px solid #e3e3e3',
  borderRadius: '10px',
  color: '#727373',
  padding: '10px 15px',
  marginTop: '0px'
})

const RateButton = styled('button')({
  background: '#2caa38',
  border: '2px solid #e3e3e3',
  borderRadius: '10px',
  color: '#fff',
  padding: '10px 15px',
  marginTop: '0px'
})

const AccountName = styled('div')({
  color: '#009c05',
  'font-weight': 'bold',
  'font-size': '13px'
})

const CommentData = styled('div')({
  width: '100%',
  'word-break': 'break-word',
  marginTop: '5px'
})

const DiscussHeader = styled('div')({
  fontSize: '18px',
  fontWeight: 'bold',
  padding: "15px 18px 25px"
})

const UserAvtar = styled('div')({
  display: 'inline-block',
  width: '50px',
  height: '40px',
  verticalAlign: 'top',
  paddingTop: '3px'
})

const CommentUserAvtar = styled('div')({
  display: 'inline-block',
  width: '50px',
  height: '40px',
  verticalAlign: 'top',
  paddingTop: '12px'
})

const CommentDetail = styled('div')({
  display: 'inline-block',
  width: 'calc(100% - 50px)',
  boxSizing: 'border-box',
  padding: '10px 20px 20px',
  minHeight: '80px',
  border: '1px solid #e8e4e4',
  borderRadius: '5px',
  lineHeight: '1.3',
  textAlign: 'justify'
})

const CourseContent = styled('span')({
  fontWeight: 700,
  fontSize: "15px",
  marginRight: '5px'
})
// Customizable Area End
