import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "../../JobListing2/src/assets";
import { Service, Category } from './types'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { sendAPIRequest } from '../../../components/src/utils'
import { sendNewServiceCreatedMessage, sendServiceUpdatedMessage } from './Messages'
import { getStorageData } from "../../../framework/src/Utilities";
yup.addMethod(yup.string, "phoneNumber", function(){
  const validationError = "Please enter a valid phone number"
  return this.test('test-name', validationError, function(value: string | undefined){
    if((value || "").slice(0, 3) === '+91') { // indian number
      const re =  /^(\+91)\d{10}$/
      if(re.test(value || "")) return true
      return this.createError({message: "Number starting with +91 must have 10 digits"})
    } else {
      const re = /^\+\d{5,15}$/
      return re.test(value || "")
    }
    return false
  })
})

yup.addMethod(yup.mixed, "maxFileSize", function(error: string, maxSize: number) {
  return this.test('test-name', error, function(file: File|undefined){
    if(!file) return true
    if(file.size>maxSize) return false
    return true
  })
})

yup.addMethod(yup.mixed, "fileType", function(fileTypes: string[], error: string){
  return this.test('test-name', error, function(file?: File){
    if(!file) return true
    return fileTypes.some(
      ft => (!!file.type.match(ft))
    )
  })
})

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  services: Service[];
  selectedService?: Service;
  selectedMyTab: number;
  modal: 'apply' | 'create' | null;
  loadingServices: boolean;
  filterQuery: string;
  randomServiceId?: string;
  getServiceDetailCallId?: string;
  service?: any;
  submittingForm?: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateServiceController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiServicesFilterCallId: string = ""
  apiCategoryListCallId: string = ""
  apiCreateServiceCallId: string = ""
  apiApplyServiceCallId: string = ""
  apiFavServiceCallIds: string[] = []
  apiUnfavServiceCallIds: string[] = []
  generateServiceIdCallId: string = ""
  favCallIdToPostId:{[key: string]: string} = {}
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: '',
      services: [],
      selectedMyTab: 0,
      modal: null,
      loadingServices: false,
      filterQuery: '',
      randomServiceId: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (responseJson) {
         if (apiRequestCallId === this.apiCreateServiceCallId){
          if(responseJson?.data?.attributes) {
            const newService = responseJson.data.attributes
            const isEditPage = this.props.navigation.getParam('id')
            if(isEditPage) {
              toast.success('Service Updated Successfully')
              sendServiceUpdatedMessage(newService)
            } else {
              toast.success('Service Created Successfully')
              sendNewServiceCreatedMessage(newService)
            }
            this.setState({ modal: null, submittingForm: true})
            this.props.navigation.goBack()
          }
        } else if(apiRequestCallId === this.generateServiceIdCallId){
          this.setState({ randomServiceId: responseJson.service_id })
        } else if(apiRequestCallId === this.state.getServiceDetailCallId){
          this.setState({ service: responseJson.data.attributes })
        }
      }
    }
    // Customizable Area End

  }



  // Customizable Area Start
  phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  urlRegExp = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm
  
  

  createFormSchema = yup.object({
    service_name: yup.string().label('Title').required().max(255),
    service_id_1: yup.string().label('Unique ID').required().matches(/^[a-zA-Z0-9]+$/, "Only alphabets and numbers are accepted"),
    service_id_2: yup.string().label('Unique ID').required().matches(/^[a-zA-Z0-9]+$/, "Only alphabets and numbers are accepted"),
    preference_ids: yup.array().min(1, 'Select atleast one Preference'),
    category_ids: yup.array().min(1, 'Select atleast one Category'),
    sub_category_ids: yup.array().min(1, 'Select atleast one Sub-Category'),
    coin_discount: yup.number().required().label('Coin discount').min(0).max(100, "Cannot be more than 100"),
    // equivalent_coins: yup.number().required().min(0, "Should be atleast 0"),
    equivalent_coins: yup.number().when("coin_discount", {
        is: (coin_discount) => coin_discount >0,
        then: yup.number().label('Equivalent Coins').min(1,"Minimum value should be one").required('Coin should be there'),
    description: yup.string().label('Description').required().max(1000)}),
    location: yup.string().label('Location').required(),
    price_min_usd: yup.number().typeError('Minimum Price must be a number').label('Minimum Price').required().positive().lessThan(yup.ref('price_max_usd'), 'Should be less than max price($)'),
    price_min_inr: yup.number().typeError('Minimum price must be a number').label('Minimum price').notRequired().positive().lessThan(yup.ref('price_max_inr'), 'Should be less than max price(rs)'),
    price_max_usd: yup.number().typeError('Maximum Price must be a number').label('Maximum Price').required().positive().moreThan(yup.ref('price_min_usd'), "Should be greater than min price($)"),
    price_max_inr: yup.number().typeError('Maximum Price must be a number').label('Maximum Price').notRequired().positive().moreThan(yup.ref('price_min_inr'), 'Should be greater than min price(rs)'),
    attachments: yup.mixed()
      // @ts-ignore
      .maxFileSize("Image cannot be more than 2Mb", 2*1024*1024)
      // @ts-ignore
      // .fileType(['image/jpeg', 'image/jpg', 'image/png'], 'Image must be a jpeg or png file'),
  })


  async componentDidMount() {
    // @todo call account data block for token
    const id = this.props.navigation.getParam('id')
    const authToken = await getStorageData('authToken');

    this.setState({token: authToken}, () => {
      if (id) {
        const msg = sendAPIRequest(configJSON.serviceDetailEndpoint.replace(':id', id), {
          method: 'GET',
          headers: { 'token': authToken }
        })
        this.setState({
          getServiceDetailCallId: msg
        })
      } else {
        this.generateServiceIdCallId = sendAPIRequest(configJSON.generateServiceId, {
          method: 'GET',
          headers: { 'token': authToken }
        })
      }
    })
  }
  

  sendGetRequest = (endpoint: string, ) => {
    const headers = {
      "Content-Type": "application/json",
      "token": this.state.token,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    const callId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return callId;
  }
  
  showCreateModal = () => { this.setState({ modal: 'create' }) }

  hideModal = () => { this.props.navigation.goBack() }

  handleCreateServiceSubmit = (data: any) => {
    let { service_id_1="", service_id_2="", attachments="", images=[], preference_ids=[], category_ids=[], sub_category_ids=[],uploaded_image=[], ...reqData } = {  ...data }
    reqData['service_id'] = `${service_id_1}`
    reqData['service_random_id'] = `${service_id_2}`
    
    if(this.props.navigation.getParam('id')) {
      (['id', 'account_id', 'service_unique_id', 'name_of_user', 'total_likes', 'is_liked', 'like_id', 'total_comments', 'shares', 'created_at', 'is_favourite', 'favourite_id', 'created_by', 'service_id', 'service_random_id', 'images' ]).forEach((key: string)=>{delete reqData[key]})
    }
    const body = new FormData()

    preference_ids.forEach((id: any)=>body.append('service[preference_ids][]', id))
    category_ids.forEach((id: any)=>body.append('service[category_ids][]', id))
    sub_category_ids.forEach((id: any) => body.append('service[sub_category_ids][]', id))
    // For Edit mode
    uploaded_image.forEach((im: any)=> body.append('service[images_ids][]', im.id))

    if(Array.isArray(images)) 
      images.forEach((im: any) => body.append('service[images][]', im))
    if(attachments)
      attachments.forEach((a: any)=>{
        if(!a.id)
          body.append(`service[attachments][]`, a as string)
        else
          body.append(`service[attachments_ids][]`, a.id as string)
      })
        
    Object.entries(reqData).forEach(data => body.append(`service[${data[0]}]`, data[1] as string))
    for (const value of body.entries()) {
      console.log("value ", value);
    }
    
    const headers = {
      "token": this.state.token,
    }
    let url = configJSON.serviceCreateAPIEndpoint
    let method = 'POST'
    const serviceId = this.props.navigation.getParam('id')
    if(serviceId) {
      url = configJSON.serviceDetailEndpoint.replace(':id', serviceId)
      method = 'PATCH';
      
      console.log({serviceUpdateBody: Array.from(body.keys())})
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.apiCreateServiceCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    this.setState({ submittingForm: true })
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  

  // Customizable Area End
  
}
