import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from 'react-toastify'
import { sendAPIRequest } from '../../../components/src/utils'
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  data: any;
  initCall?: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  subscriptions: any;
  data: any;
  subscribtionPlan:boolean;
  subscribtionHistory:boolean;
  choosePackPopup:boolean;
  addMoreLicensePopUp:boolean;
  subscribtionList:any[];
  SubscribtionHistoryList:any[];
  subscribtionListPopUpData:any[];
  loader:boolean;
  activeChooseButton:boolean;
  activeSubscribtionHistoryButton:boolean;
  requestChoosePackagePending:boolean;
  requestChoosePackageApproved:boolean; 
  requestChoosePackage:any;
  subscribtionCardID:number;
  requestpmCorporatetValue:string;
  requestindividualValue:string;
  variableCorporateInrCost:number;
  paymentSucess:boolean;
  stripeOpen:boolean;
  stripelink:any;
  paynowStripeId:any;
  dollar:string;
  selectedSubscription?: any;
  transactionData: any;
  selectedTransactionData: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableusersubscriptionsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getListCallId: any;
  apiGetAllSubscribtionPlanCallId:string="";
  apiGetAllSubscribtionHistoryCallId:string="";
  postRequestApiId:string="";
  postPaymentApiId:string="";
  payNowId:string="";
  userSubscribtionBuy:string="";
  getshowsubscribtionRequestid:string=""
  addLicenseCallId: string = ""
  reactiveLicenseCallId: string = ""
  reactivateCallId: string = ""
  paymentSucessfullClosePopup=()=>{
    this.setState({
      paymentSucess:false
    })
  }
  chooseSubscribtionPlan=()=>{
      this.setState({activeSubscribtionHistoryButton:false})
       this.setState({activeChooseButton:true})
       this.setState({subscribtionPlan:true});
       this.setState({subscribtionHistory:false});
    }
    chooseSubscribtionHistory=()=>{
    
      this.setState({activeChooseButton:false})
      this.setState({activeSubscribtionHistoryButton:true})
      this.setState({subscribtionPlan:false});
      this.setState({subscribtionHistory:true});
      console.log("History called...")
      this.getAllSubscribtionHistory();
   
    }
    choosePackagePopUp = (ele:any,index:any) => {
      console.log("Choose Package",ele)
      if(ele.attributes.is_purchased){
        toast.warn('Already purchased this package')
        return
      }
      // this.setState({choosePackPopup:true})
      this.setState({choosePackPopup: true,subscribtionListPopUpData: ele,
        requestChoosePackage: ele
      });
      // this.showSubscribtionRequest(ele);
    }
    closeChoosePackPopup = () => {
      this.setState({choosePackPopup:false})
      // this.props.navigation.goBack();
  
    }

    // closeSuccessModal = () => {
    //   this.props.navigation.history.push("/Customisableusersubscriptions")
    // }    
    addMoreLicensePopUp = (ele: any) => {
      this.setState({
        addMoreLicensePopUp:true,
        selectedSubscription: ele
      })
  
    }
    handleAddLicenseSubmit = async(attributes: any) => {
    const authToken= await getStorageData('authToken');

        this.addLicenseCallId = sendAPIRequest(configJSON.moreLicense, {
        method: 'POST',
        body: {data: {attributes}},
        headers: {
          token: authToken,
          'Content-Type': "application/json"
        }
      })
    }
    handleReactiveLicense = async(id: any, cur: any) => {
      const authToken= await getStorageData('authToken');
      this.reactiveLicenseCallId = sendAPIRequest(configJSON.reactiveLicense, {
      method: 'POST',
      body: {user_subscription_id: id, currency: cur},
      headers: {
        token: authToken,
        'Content-Type': "application/json"
      }
      })
    }
    closeAddMoreLicensePopUp=()=>{
      this.setState({addMoreLicensePopUp:false})  
  
    }
    postPaymentDetails=(carddetails:any,totalmem:number,inr:number,usd:string)=>{
      this.setState({choosePackPopup:false})
      console.log("Subscribtion Id from Request Plan response in pay now",this.state.requestChoosePackage.attributes.subscription_id)
      
      console.log("Paynow post Api Called...",carddetails)
      console.log("Save Details On State Request",this.state.requestChoosePackage)
      console.log("Account Id",this.state.requestChoosePackage.attributes.account_id)
      console.log("Id Stripe",this.state.requestChoosePackage.attributes.subscription_id)
      this.setState({dollar:usd})
      
      console.log("Inr",inr)
      console.log("usd",usd)
  
  
      const header = {
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      console.log("cards name",inr)
      
      const { license_coverd, period, price, price_inr, additional_pm_corpo_license_usd, additional_individual_license_usd, additional_pm_corpo_license_inr, additional_individual__license_inr} = carddetails.attributes
      
      const total_member = 1+Number(this.state.requestpmCorporatetValue)+Number(this.state.requestindividualValue) + license_coverd
      const total_cost_inr = price_inr+period*(  Number(this.state.requestpmCorporatetValue) * additional_pm_corpo_license_inr + Number(this.state.requestindividualValue)*additional_individual__license_inr);
      const total_cost_usd = Number(price)+period*( Number(this.state.requestpmCorporatetValue) * additional_pm_corpo_license_usd + Number(this.state.requestindividualValue)*additional_individual_license_usd)
      const httpBody = {
        "data":{
          "attributes": { 
            "subscription_id": carddetails.id,
            "add_pm_corpo_lice_coverd": this.state.requestpmCorporatetValue == '' ? 0 : this.state.requestpmCorporatetValue,
            "add_indivi_user_lice": this.state.requestindividualValue == '' ? 0 : this.state.requestindividualValue,
            total_member,
            total_cost_inr,
            total_cost_usd,
            "currency": "inr",
            }
          }
        
      };
      this.userSubscribtionBuy = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postpaymentDetailtUrl
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
     requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.paymentpostSubscribtionPlan
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
     
     console.log("ID final",this.state.paynowStripeId.attributes.subscription_id)
      }
   
    getCorporateVariableCost=(cost:any)=>{
      
      this.setState({
        requestpmCorporatetValue:cost
      })
    
  
    }
    getindividualVariableCost=(cost:any)=>{
      this.setState({
        requestindividualValue:cost
      })
  
    }
    variablePmCorporateCost=(Totalcost:number)=>{
      console.log("Variable cost IN state",Totalcost)
      this.setState({
        variableCorporateInrCost:Totalcost
      })
    }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      subscriptions: [],
      data: null,
      subscribtionPlan:true,
      subscribtionHistory:false,
      choosePackPopup:false,
      addMoreLicensePopUp:false,
      subscribtionList:[],
      SubscribtionHistoryList:[],
      subscribtionListPopUpData:[],
      loader:true,
      activeChooseButton:true,
      activeSubscribtionHistoryButton:false,
      requestChoosePackagePending:true,
      requestChoosePackageApproved:false,
      requestChoosePackage:[],
      subscribtionCardID:0,
      requestpmCorporatetValue:"",
      requestindividualValue:"",
      variableCorporateInrCost:0,
      paymentSucess:true,
      stripeOpen:false,
      stripelink:"",
      paynowStripeId:"",
      dollar:"",
      transactionData: null,
      selectedTransactionData: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
   // Show aLL Subscribtion GET method
   if (
  getName(MessageEnum.RestAPIResponceMessage) === message.id &&
  this.apiGetAllSubscribtionPlanCallId &&
  this.apiGetAllSubscribtionPlanCallId ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
) {
  const apiResponse = message.getData(
     getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
if(apiResponse?.errors){
console.log('error for getAllProductsList API', apiResponse?.errors)
} else if(Array.isArray(apiResponse.data)){
  console.log('Response for getAllProductsList API', apiResponse);
  console.log('Subbbbbb', apiResponse.data[0].attributes.name);

  this.setState({
  subscribtionList : apiResponse?.data
  })  
}
else {
}
}
 // Request Subscirbtion Plan
    // postRequestApiId

if (
  getName(MessageEnum.RestAPIResponceMessage) === message.id &&
  this.postRequestApiId &&
  this.postRequestApiId ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
) {
  const apiReqResponse = message.getData(
     getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
if(apiReqResponse?.errors){
console.log('error for postRequestSubscribtion API', apiReqResponse?.errors)
}
else {
  console.log('Response for Create Subscribtion request...', apiReqResponse);
  this.setState({
    requestChoosePackage : apiReqResponse?.data
    })
    console.log("Request Choose package after PayNOw Button",this.state.requestChoosePackage)
    console.log("Account Id from Request Plan response",this.state.requestChoosePackage.attributes.account_id)
    console.log("Subscribtion Id from Request Plan response",this.state.requestChoosePackage.attributes.subscription_id)


    this.getAllSubscribtionPlan()
 
}
}
//getshowsubscribtionRequestid
if (
  getName(MessageEnum.RestAPIResponceMessage) === message.id &&
  this.getshowsubscribtionRequestid &&
  this.getshowsubscribtionRequestid ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
) {
  const apiReqResponse = message.getData(
     getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
if(apiReqResponse?.errors){
console.log('error for getshowsubscribtionRequestid API', apiReqResponse?.errors)
}
else {
  console.log('Response for getshowsubscribtionRequestid', apiReqResponse);
  this.setState({
    requestChoosePackage : apiReqResponse?.data
    })
    // console.log("Request Choose package after PayNOw Button",this.state.requestChoosePackage)
    // console.log("Account Id from Request Plan response",this.state.requestChoosePackage.attributes.account_id)
    // console.log("Subscribtion Id from Request Plan response",this.state.requestChoosePackage.attributes.id)


    this.getAllSubscribtionPlan()
 
}
}
if (
  getName(MessageEnum.RestAPIResponceMessage) === message.id &&
  this.userSubscribtionBuy &&
  this.userSubscribtionBuy ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
) {
  const apiReqResponse = message.getData(
     getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
if(apiReqResponse?.errors){
console.log('error for puserSubscribtion Buy', apiReqResponse?.errors)
}
else {
  console.log('Response for userSubscribtion Buy....', apiReqResponse);
  window.location = apiReqResponse.url
  // this.setState({paynowStripeId:apiReqResponse?.data})
  // console.log("State ID",this.state.paynowStripeId)

  // console.log("ID to pass to stripe",apiReqResponse.data.attributes.subscription_id)
  // this.setState({paynowStripeId:apiReqResponse?.data})
  // this.payNowFuncton(apiReqResponse.data.id,"usd",this.state.dollar)
    // this.getAllSubscribtionPlan()
 
}
}
// Subscribtion History
if (
  getName(MessageEnum.RestAPIResponceMessage) === message.id &&
  this.apiGetAllSubscribtionHistoryCallId &&
  this.apiGetAllSubscribtionHistoryCallId ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
) 
{
  const apiHistoryResponse = message.getData(
     getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
if(apiHistoryResponse?.errors){
console.log('error for GetSubscribtionHIstory API', apiHistoryResponse?.errors)
}
else {
  console.log('Response for GetSubscribtionHIstory API', apiHistoryResponse);
  this.setState({
    SubscribtionHistoryList : apiHistoryResponse?.data,
    loader: false
    })
    console.log("state history",this.state.SubscribtionHistoryList.length)
 
}
}

// Subscribtion History ENd
// payment
if(this.reactivateCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
  const apiPayNowResponse = message.getData(
     getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  if(apiPayNowResponse.url) {
    window.location.replace(apiPayNowResponse.url);
  }
  }
if (
  getName(MessageEnum.RestAPIResponceMessage) === message.id &&
  this.payNowId &&
  this.payNowId ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
) 
{
  const apiPayNowResponse = message.getData(
     getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
if(apiPayNowResponse?.errors){
console.log('error for PayNow Post API', apiPayNowResponse?.errors)
}
else {
  console.log('Response for PayNOw Stripe Integration API', apiPayNowResponse);
  this.setState({stripelink:apiPayNowResponse.url})
  window.location.replace(apiPayNowResponse.url);
  console.log("Stripe Link",this.state.stripelink)
 
 
}
}
if (
  getName(MessageEnum.RestAPIResponceMessage) === message.id &&
  this.postPaymentApiId &&
  this.postPaymentApiId ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
) {
  const apiPaymentResponse = message.getData(
     getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
if(apiPaymentResponse?.errors){
console.log('error for payment post method', apiPaymentResponse?.errors)
}
else {
  console.log('Response for payment post method', apiPaymentResponse);
  this.setState({
    requestChoosePackage : apiPaymentResponse?.data
    })
  //  this.getAllSubscribtionPlan()
}
}
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      this.setState({ data: data });
    }
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      const authToken= await getStorageData('authToken');
      
      this.setState({ token: token|| authToken }, () => {
        // this.fetchSubscriptionsList();
        this.tokenInitialized()
      });
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if(apiRequestCallId === this.addLicenseCallId) {
          if(!responseJson.url) return 
          window.location = responseJson.url
        }else if(apiRequestCallId === this.reactiveLicenseCallId) {
          if(!responseJson.url) return 
          window.location = responseJson.url
        } else if (apiRequestCallId === this.getListCallId) {
          this.setState({
            subscriptions: responseJson.data
          });
          this.setState({
            loader:false
          });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    let authToken = await getStorageData('authToken');


    this.setState({token: authToken},async() => {
      const userString = await getStorageData('user');
      const userJson = JSON.parse(userString);
      const { user_type = '' } = userJson ?? {};
      if ((user_type === "licensed_user")) {
        this.setNavigation("ActivityFeed", this.props)
      }
      if(this.props.initCall === "plan"){
        this.getAllSubscribtionPlan();
        this.fetchSubscriptionsList()
    }
    if(this.props.initCall === "history")
      this.getAllSubscribtionHistory();
    console.log({ props: this.props })
    if(window.location.pathname.indexOf('/success')>0)
      this.chooseSubscribtionHistory()
    })
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  fetchSubscriptionsList = () => {
    if(this.props.initCall !== "plan") return

    if(this.state.subscriptions?.length>0)return
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.subscriptionApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubscriptionAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  gotoSubDetailScreen(item:any) {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SubscriptionDetails");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), item);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg)
  }

  getAllSubscribtionPlan=()=>{
    const headers = {
      "Content-Type": configJSON.contentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
     
    this.apiGetAllSubscribtionPlanCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllSubscribtionPlanUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  requestPlan=(ele:any)=>{
    console.log("Show ALL subscribtion response in create subscribtion request",ele);
    console.log("Request Post ID",ele.id)
    this.setState({
      subscribtionCardID:ele.id
    })
    
    console.log("Selected Card ID",this.state.subscribtionCardID)
   
    // let currentSubscribtionList=this.state.subscribtionList;
    // ele.requestChoosePackagePending
    // currentSubscribtionList[index]=ele;
    
    this.setState({requestChoosePackagePending:false});
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postRequestApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postSubscribtionPlanRequestUrl}?subscription_id=${ele.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestSubscribtionPlan
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAllSubscribtionHistory=()=>{
    const headers = {
      "Content-Type": configJSON.contentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAllSubscribtionHistoryCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllSubscribtionHistoryUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiHistoryMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  postSubscribtionsList=(SubscribtionId:any)=>{
    console.log("Inside Function...",SubscribtionId)
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postRequestApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postSubscribtionPlanRequestUrl}?subscription_id=${SubscribtionId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestSubscribtionPlan
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    
   
  }
  paymentDetails=(carddetaild:any,totalmem:number,inr:number,usd:string)=>{
    
    console.log("Paynow post Api Called...",carddetaild)
    console.log("Paynow post Api Called...",carddetaild.id)

    console.log("Inr",inr)
    console.log("usd",usd)
    this.userSubscribtionBuyCall(carddetaild,totalmem,inr,usd);

    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const httpBody = {
      
        "user_subscription_id": carddetaild.id,
        "currency": "usd",
        "amount": usd
      
    };
    this.postPaymentApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.paymentSubscribtionPlanUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.paymentpostSubscribtionPlan
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  userSubscribtionBuyCall=(carddetails:any,totalmem:number,inr:number,usd:string)=>{

    console.log("calledd payment...")
    this.setState({choosePackPopup:false})
   
    this.requestPlan(carddetails)
    console.log("Paynow post Api Called...",carddetails)
    console.log("Save Details On State Request",this.state.requestChoosePackage)
    console.log("Account Id",this.state.requestChoosePackage.attributes.account_id)
    console.log("Id Stripe",this.state.requestChoosePackage.attributes.subscription_id)

    
    console.log("Inr",inr)
    console.log("usd",usd)


    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const httpBody = {
      "data":{
        "attributes":
        {    "account_id":this.state.requestChoosePackage.attributes.account_id,
             "subscription_id": this.state.requestChoosePackage.attributes.subscription_id,
             "package_name":carddetails.attributes.name,
             "package_role":carddetails.attributes.role,
             "member_covered":carddetails.attributes.member_covered,
             "period":carddetails.attributes.period,
             "package_start_date":carddetails.attributes.start_date,
             "package_end_date":carddetails.attributes.end_date,
             "package_discription":carddetails.attributes.description,
             "pm_corpo_lice_coverd":carddetails.attributes.license_coverd,
             "add_pm_corpo_lice_coverd":carddetails.attributes.additional_pm_corpo_license_usd == '' ? 0 : carddetails.attributes.additional_pm_corpo_license_usd,
             "add_indivi_user_lice":carddetails.attributes.additional_individual_license_usd == '' ? 0 : carddetails.attributes.additional_individual_license_usd,
             "total_member":totalmem,
             "total_cost_inr":inr,
             "total_cost_usd":usd,
             "package_status":0
             }
        }
      
    };
    this.userSubscribtionBuy = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postpaymentDetailtUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.paymentpostSubscribtionPlan
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  payNowServiceProvider=(carddetails:any,totalmem:number,inr:number,usd:string)=>{

    this.setState({choosePackPopup:false})
    console.log("Subscribtion Id from Request Plan response in pay now",this.state.requestChoosePackage.attributes.subscription_id)
    
    console.log("Paynow post Api Called...",carddetails)
    console.log("Save Details On State Request",this.state.requestChoosePackage)
    console.log("Account Id",this.state.requestChoosePackage.attributes.account_id)
    console.log("Id Stripe",this.state.requestChoosePackage.attributes.subscription_id)
    this.setState({dollar:usd})
    
    console.log("Inr",inr)
    console.log("usd",usd)


    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("cards name",inr)
    const httpBody = {
      "data":{
        "attributes":
        {    "account_id":this.state.requestChoosePackage.attributes.account_id,
             "subscription_id": carddetails.id,
             "package_name":carddetails.attributes.name,
             "package_role":carddetails.attributes.role,
             "member_covered":carddetails.attributes.member_covered,
             "period":carddetails.attributes.period,
             "package_start_date":carddetails.attributes.start_date,
             "package_end_date":carddetails.attributes.end_date,
             "package_discription":carddetails.attributes.description,
             "pm_corpo_lice_coverd":carddetails.attributes.license_coverd,
             "add_pm_corpo_lice_coverd":carddetails.attributes.additional_pm_corpo_license_usd == '' ? 0 : carddetails.attributes.additional_pm_corpo_license_usd,
             "add_indivi_user_lice":carddetails.attributes.additional_individual_license_usd == '' ? 0 : carddetails.attributes.additional_individual_license_usd,
             "total_member":totalmem,
             "total_cost_inr":inr,
             "total_cost_usd":usd,
             "package_status":0
             }
        }
      
    };
    this.userSubscribtionBuy = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postpaymentDetailtUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.paymentpostSubscribtionPlan
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
   
   console.log("ID final",this.state.paynowStripeId.attributes.subscription_id)
   
  }
  payNowFuncton=(user_subscription_id:string,currency:string,amount:string)=>{
  
    console.log("PAyNOw Called final")
    console.log("final ID",user_subscription_id)

    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const httpBody = {
      
         "user_subscription_id":user_subscription_id,
         "currency": currency,
         "amount":amount,
     
    };
    this.payNowId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.payNowUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.paymentpostSubscribtionPlan
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
   
  }
  showSubscribtionRequest=(ele:any)=>{
    console.log("function Called")
    console.log("id",ele.id)
    this.setState({requestChoosePackagePending:false});
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getshowsubscribtionRequestid = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getshowsubscribtionRequest}${ele.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  tokenInitialized = () => {
    // this.getAllSubscribtionPlan()
    // this.getAllSubscribtionHistory()
    // this.requestPlan(ele,)
    
  }
  reactivateSubscription = async(ele:any) => {
    const authToken= await getStorageData('authToken');

    const body = new FormData()
    body.append('user_subscription_id', ele.id)
    body.append('currency', 'inr')

    this.reactivateCallId = sendAPIRequest(
      configJSON.reactivateSubscribtionUrl,
      {
        method: 'POST',
        headers: {
          token: authToken
        },
        body
      }
    )
  }
  setNavigation = (route: string, props: any, payload?: any) => {
    const navMessage = new Message(getName(MessageEnum.NavigationMessage));
    navMessage.addData(getName(MessageEnum.NavigationTargetMessage), route);

    navMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);

    this.send(navMessage);
  }
  isButtonDisabled = (isPurchased: boolean, isPurchaseable: boolean): boolean => {
    return isPurchased || !isPurchaseable;
  };
  getBackgroundColor = (licenseCovered: number | null) => {
    return licenseCovered === null ? '#C9C9C9' : '#F3F8F3';
  }
  getPaymentMethodLabel = (paymentMethod: string) => {
    return paymentMethod === "card" ? "Credit Card" : paymentMethod;
  }
  formatCurrencyDisplay = (usd: string | null, inr: string | null) => {
    const formattedUsd = usd && usd !== '' ? usd : '-';
    const formattedInr = inr && inr !== '' ? inr : '-';
    return `${formattedUsd} / ₹${formattedInr} per licence per month`;
  };
  isPlanActive = (packageStatus : string) => {
    return packageStatus ==='Active' ? false : true
  }
  // Customizable Area End
}
