import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as yup from 'yup'
import { toast } from 'react-toastify';
import { sendAPIRequest } from '../../../components/src/utils';
import { sendSharePopupMessage } from '../../share/src/Messages';
import { sendAddRequestMessage } from '../../RequestManagement/src/Messages'
import { sendJobApplyMessage } from './Messages'
import { sendNewJobCreatedMessage, sendJobUpdatedMessage } from './Messages'
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  openModal: boolean;
  courseData: any;
  courseId: string;
  courseCommentData: any[];
  pagination?: {
    next_page?: number;
  };
  userComment: string;
  userReply: string;
  reply_id: '';
  openCommentEmoji: boolean;
  openCommentReplyEmoji: boolean;
  openReply: '';
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class JobDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getCourseDetailCallId = '';
  getAllCourseCommentCallId = '';
  courseCommentCallId = '';
  courseLikeCallId = '';
  courseCommentReplyCallId = '';
  getMoreCommentCallId = '';
  apiFavCourseCallId: string[] = []
  favCallIdToCourseId: { [key: string]: string } = {}
  likeCallIdToCommentId: { [key: string]: string } = {}

  async componentDidMount() {
    super.componentDidMount();
    
    const authToken = await getStorageData('authToken');
    if (authToken == null) {
      this.props.navigation.navigate("Home");
    } else {
    }

    this.onInitialized();
  }

  onInitialized = async() => {
    const token=await getStorageData('authToken');
    const id = this.props.navigation.getParam('id')
    this.getCourseDetailCallId = sendAPIRequest(configJSON.jobDetailEndpoint.replace(':id', id), {
      method: 'GET',
      headers: { token }
    })

    this.getAllComments();
  }

  getAllComments = async() => {
    const token=await getStorageData('authToken');
    const id = this.props.navigation.getParam('id')
    this.getAllCourseCommentCallId = sendAPIRequest(configJSON.getAllJobCommentEndpoint.replace(':id', id), {
      method: 'GET',
      headers: { token }
    })
  }
  onCloseModal = () => {
    this.setState({ openModal: false });
    if(this.props.navigation.history.location.pathname.includes('JobListing2')){
      this.props.navigation.history.push(`/MyMarketplace/JobListing2`,{comment:true});
    }
    else {
      this.props.navigation.goBack()
    }
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReportModalOpenMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: '',
      openModal: true,
      courseId: '',
      courseData: {},
      courseCommentData: [],
      userComment: '',
      userReply: '',
      reply_id: '',
      openCommentEmoji: false,
      openCommentReplyEmoji: false,
      openReply: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
      if (responseJson && responseJson.errors) {
        // this.setState({ refresh: false });
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (this.getCourseDetailCallId === apiRequestCallId){
        const courseData = { ...responseJson.data.attributes, id: responseJson.data.id }
        this.setState({ 
          courseData
        })
      } else if (this.getAllCourseCommentCallId === apiRequestCallId){
        let courseCommentData = responseJson?.comments?.data?.map((d: any) => ({ ...d.attributes, id: d.id })) || []
        this.setState({ 
          courseCommentData, pagination: responseJson?.meta?.pagination
        })
      } else if (this.courseCommentCallId === apiRequestCallId){
        let userComment = '';
        this.setState({ 
          userComment
        })
        this.getAllComments();
      } else if (this.courseLikeCallId === apiRequestCallId){
        const comment_id = this.likeCallIdToCommentId[apiRequestCallId];
        let courseCommentData = this.state.courseCommentData;
        let updateItem = courseCommentData.find((x) => x.id == comment_id);
        let index = courseCommentData.indexOf(updateItem);
        delete this.likeCallIdToCommentId[apiRequestCallId]
        //let comment_id = responseJson?.data?.attributes?.likeable_id
        if (responseJson?.data) {
          updateItem.like_count += 1;
          updateItem.is_like = true;
          const likeData = { ...responseJson.data, ...responseJson.data?.attributes }
          updateItem.like_id = [...(this.state.courseData.like_id || []), likeData];
          courseCommentData[index] = updateItem;
          this.setState({courseCommentData});
        }
        else {
          updateItem.like_count -= 1;
          updateItem.is_like = false;
          updateItem.like_id = [];
          courseCommentData[index] = updateItem;
          this.setState({courseCommentData});
        }
      } else if (this.courseCommentReplyCallId === apiRequestCallId){
        let new_reply = responseJson.data ? { ...responseJson.data.attributes, id: responseJson.data.id, like_count: 0 } : []
        if (new_reply) {
          let courseCommentData = this.state.courseCommentData;
          let updateItem = courseCommentData.find((x) => x.id == this.state.reply_id);
          let index = courseCommentData.indexOf(updateItem);
          updateItem.replies.push(new_reply);
          courseCommentData[index] = updateItem;
          let userReply = '';
          this.setState({ 
            userReply, courseCommentData, reply_id: ''
          })
        }
      } else if (this.getMoreCommentCallId === apiRequestCallId){
        let oldCourseCommentData = responseJson?.comments?.data?.map((d: any) => ({ ...d.attributes, id: d.id })) || []
        let courseCommentData = [...this.state.courseCommentData, ...oldCourseCommentData] ;
        this.setState({ 
          courseCommentData, pagination: responseJson?.meta?.pagination
        })
      } else if (this.apiFavCourseCallId.includes(apiRequestCallId)) {
        const postId = this.favCallIdToCourseId[apiRequestCallId]
        this.apiFavCourseCallId = this.apiFavCourseCallId.filter(x => x !== apiRequestCallId)
        delete this.favCallIdToCourseId[apiRequestCallId]
        if (responseJson.data) {
          const fav = { ...responseJson.data, ...responseJson.data?.attributes }
          let courseData = { ...this.state.courseData, favourite_id: [...(this.state.courseData.favourite_id || []), fav] }
          this.setState({ courseData })
          const newJob = {id:responseJson.data.id, ...courseData}
          sendJobUpdatedMessage(newJob)
        }
      }
       
    }
    // Customizable Area End

  }

  // Customizable Area Start
  handleShareCourse = (p: any) => () => {
    let data = {
      userId: p?.created_by?.id,
      postId: p?.id,
      link: `${window?.location?.origin}/user/${JSON.parse(p?.created_by?.id || '0')}`,
      shareableType: 'BxBlockJoblisting2::Job',
      shareableId: p?.id,
    }
    sendSharePopupMessage(data);
  }

  handleCourseCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ userComment: e.target.value })
  }

  handleCourseReplyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ userReply: e.target.value })
  }

  handleCourseComment = async(id: any) => {
    const token=await getStorageData('authToken');
    const { userComment } = this.state
    const body = new FormData()
    body.append("job_id", id)
    body.append("content", userComment);

    this.courseCommentCallId = sendAPIRequest(configJSON.jobCommentEndpoint, {
      method: 'POST',
      body,
      headers: { token }
    })

    let stateEmogi = false;
    this.setState({ openCommentEmoji: stateEmogi });
  }

  handleCommentLike = async(comment: any) => {
    const token=await getStorageData('authToken');
    const body = new FormData()
    body.append("data[attributes][likeable_type]", 'Comment')
    body.append("data[attributes][likeable_id]", comment.id);
    let url = configJSON.jobLikeEndpoint;
    if (comment.is_like) {
      url = configJSON.jobLikeEndpoint + '/' + comment?.like_id[0]?.id
    }
    this.courseLikeCallId = sendAPIRequest(url, {
      method: comment.is_like ? 'DELETE' : 'POST',
      body,
      headers: { token }
    })
    this.likeCallIdToCommentId[this.courseLikeCallId] = comment.id
  }

  handleSetReplyId = (reply_id: any) => {
    this.setState({
      reply_id
    });
  }

  handleReplyComment = async() => {
    const token=await getStorageData('authToken');
    const { userReply, reply_id } = this.state
    const body = new FormData()
    body.append("comment_id", reply_id)
    body.append("content", userReply);

    this.courseCommentReplyCallId = sendAPIRequest(configJSON.jobCommentReplyEndpoint, {
      method: 'POST',
      body,
      headers: { token }
    })

    let stateEmogi = false;
    this.setState({ openCommentReplyEmoji: stateEmogi });
  }

  loadMore = async() => {
    const token=await getStorageData('authToken');
    const id = this.props.navigation.getParam('id')
    let course_url = configJSON.getAllJobCommentEndpoint.replace(':cid', id) + '&page=' + this.state.pagination?.next_page;
    this.getMoreCommentCallId = sendAPIRequest(course_url, {
      method: 'GET',
      headers: { token }
    })
  }

  onLikeChange = (postId: any, delta: number) => {
    let courseData = this.state.courseData;
    courseData.total_likes += delta;
    courseData.like_id = [];
    this.setState({ courseData })
  }
  onLikeIdUpdate = (postId: any, likeObj: any) => {
    let courseData = this.state.courseData;
    courseData.like_id = [likeObj];
    this.setState({ courseData })
  }

  handleFavoriteClick = async() => {
    let courseData = this.state.courseData;
    console.log(courseData, 'course DATA')
    // if a fav request already exists, skip this one
    //if (Array.from(Object.values(this.favCallIdToPostId)).includes(postId)) return;

    if (courseData) {
      let endpoint = courseData.is_favourite ? configJSON.unfavJobAPIEndpoint : configJSON.favJobAPIEndpoint
      const method = courseData.is_favourite ? 'DELETE' : 'POST'
      if (!courseData.is_favourite) {
        const token=await getStorageData('authToken');
        // POST REQUEST
        const body = new FormData()
        body.append("data[favouriteable_type]", "BxBlockJoblisting2::Job")
        body.append("data[favouriteable_id]", courseData.id as string)
        const callId = sendAPIRequest(endpoint, { body, method, headers: { token: token } })
        this.apiFavCourseCallId.push(callId)
        this.favCallIdToCourseId[callId] = courseData.id
      } else {
        const token=await getStorageData('authToken');
        // DELETE REQUEST
        if (!courseData.favourite_id?.length) return;

        endpoint = endpoint.replace(':id', String(courseData.favourite_id[0].id))
        sendAPIRequest(endpoint, { method, headers: { token: token } })
      }
      // optimistic update
      courseData.is_favourite = !courseData.is_favourite
      courseData.favourite_id = []
      this.setState({ courseData })
      const newJob = {id:courseData.id, ...courseData}
      sendJobUpdatedMessage(newJob)
    }
  }

  onCommentEmojiClick = (event: any, emojiObject: any) => {
    let emoji = emojiObject?.emoji;
    let userComment = this.state.userComment + emoji;
    this.setState({ userComment });
  }

  openEmojiComment = () => {
    let stateEmogi = !this.state.openCommentEmoji;
    this.setState({ openCommentEmoji: stateEmogi });
  }

  openEmojiCommentReply = () => {
    let stateEmogi = !this.state.openCommentReplyEmoji;
    this.setState({ openCommentReplyEmoji: stateEmogi });
  }

  onReplyEmojiClick = (event: any, emojiObject: any) => {
    let emoji = emojiObject?.emoji;
    let userReply = this.state.userReply + emoji;
    this.setState({ userReply });
  }

  showReply = (comment_id: any) => {
    this.setState({ openReply: comment_id });
  }

  handleJobApply = () => {
    if(this.context.id == this.state.courseData?.created_by?.id){
      toast.warn('Cannot apply for your own job post')
      return 
    }
    setTimeout(()=>sendJobApplyMessage(this.state.courseData), 500)
    this.props.navigation.goBack()
  }

  formatSalary = (salaryInDollar:number, salaryInRs:number) => {
    const rsPart = salaryInRs ? ` (₹${salaryInRs})` : '';
    return `$${salaryInDollar}${rsPart}`;
  };
  // Customizable Area End
  
}
