import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { Product } from './types'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { sendNewProductCreatedMessage, sendProductUpdatedMessage } from './Message'

import { sendAPIRequest } from '../../../components/src/utils'

import { getStorageData } from "../../../framework/src/Utilities";


yup.addMethod(yup.mixed, "maxFileSize", function(error: string, maxSize: number) {
  return this.test('test-name', error, function(file: File[]|undefined){
    if(!file) return true
    const arr = Array.isArray(file)?file: [file]
    return !arr.some(f => f.size>maxSize)
  })
})
yup.addMethod(yup.mixed, "maxTotalFileSize", function(error: string, maxSize: number) {
  return this.test('test-name', error, function(file: File[]|undefined){
    if(!file) return true
    // @ts-ignore
    return !(file.reduce((a: File, b: File) => ((a.size) + (b.size)), 0) > maxSize)
  })
})
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedMyTab: number;
  modal: 'create' | 'rate' | '';
  categories: any[];
  token: string;
  products: Product[];
  selectedProduct?: Product;
  modalRating: number;
  loadingPosts: boolean;
  filterQuery: string;
  userRole?: any;
  pagination?: any;
  randomProductId?: string;
  submittingForm?: boolean;
  product?: any
  getProductDetailCallId?: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateProductController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  productCreateCallId: string = ""
  productListCallId: string = ""
  categoryListCallId: string = ""
  productFilterCallId: string = ""
  apiFavCallIds: string[] = []
  apiUnfavCallIds: string[] = []
  favCallIdToPostId: {[key: string]: string} = {}
  userRoleCallId:string = "";
  generateJobIdCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedMyTab: 2,
      modal: '',
      categories: [],
      token: '',
      products: [],
      modalRating: 0,
      loadingPosts: false,
      filterQuery: '',
      userRole: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.errors) {
        // this.setState({ refresh: false });
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if ( apiRequestCallId === this.productCreateCallId ) {
        if(responseJson.data) {
          const newProduct = {id:responseJson.data.id, ...responseJson.data.attributes}
          const isEditPage = this.props.navigation.getParam('id')
          if(isEditPage) {
            toast.success('Product updated successfully')
            sendProductUpdatedMessage(newProduct)
          } else {
            sendNewProductCreatedMessage(newProduct)
            toast.success('Created Product Successfully')          
          }
          this.props.navigation.goBack()
        }
        this.setState({ submittingForm: false })
      } else if(apiRequestCallId === this.generateJobIdCallId) {
        this.setState({ randomProductId: responseJson.product_unique_id })
      } else if ( apiRequestCallId === this.categoryListCallId ) {
        if(Array.isArray(responseJson))
            this.setState({categories: responseJson})
      } else if (apiRequestCallId === this.userRoleCallId) {
        // toast.success('Community created successfully')
        this.setState({ 
          userRole: responseJson.user_role || ''
        })
      } else if(apiRequestCallId === this.state.getProductDetailCallId){
        this.setState({ product: responseJson.data.attributes })
      }
    }
    // Customizable Area End

  }

  // Customizable Area Start
  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  createFormSchema = yup.object({
      product_id_1: yup.string()
        .label('Product ID')
        .required()
        .matches(/^[a-zA-Z0-9]+$/, 'Product ID can only contain Alpha Numeric Characters'),
      product_id_2: yup.string()
        .label('Product ID')
        .required()
        .matches(/^[a-zA-Z0-9]+$/, 'Product ID can only contain Alpha Numeric Characters'),
      product_name: yup.string().label('Product Name').required().max(255),
      preference_ids: yup.array().min(1, "Atleast one preference required"),
      category_ids: yup.array().min(1, "Atleast one category required"),
      sub_category_ids: yup.array().min(1, "Atleast one Sub Category required"), 
      website_link: yup.string().url().label('Website Link').required(),
      price: yup.number().typeError('Price must be a number').required().label("Price"),
      price_inr: yup.number().typeError('Price must be a number').notRequired().label("Price"),
      coin_discount: yup.number().typeError('Coin Discount accepts only number').min(0, 'Cannot be less than 0').required().label("Coin Discount").max(100),
      // equivalent_coins: yup.number().min(0, 'Cannot be less than 0').required().label("Equivalent Coins"),
      equivalent_coins: yup.number().when("coin_discount", {
        is: (coin_discount) => coin_discount >0,
        then: yup.number().typeError('Equivalent Coins must be a number').label('Equivalent Coins').min(1,"Minimum value should be one").required('Coin should be there')}),
      description: yup.string().required().label("Description").max(1000),
      image: yup
        .array()
        .min(1, "Atleast one image is required")
        .required()
        .label("Product Image")
        // @ts-ignore
        // .maxFileSize("Max allowed file size is 500kB", 500*1024)
        // @ts-ignore
        .maxTotalFileSize("Max allowed total file size is 20Mb", 20*1024*1024),
    })

    updateFormSchema = yup.object({
      product_id_1: yup.string()
        .label('Product ID')
        .required()
        .matches(/^[a-zA-Z0-9]+$/, 'Product ID can only contain Alpha Numeric Characters'),
      product_id_2: yup.string()
        .label('Product ID')
        .required()
        .matches(/^[a-zA-Z0-9]+$/, 'Product ID can only contain Alpha Numeric Characters'),
      product_name: yup.string().label('Product Name').required().max(255),
      preference_ids: yup.array().min(1, "Atleast one preference required"),
      category_ids: yup.array().min(1, "Atleast one category required"),
      sub_category_ids: yup.array().min(1, "Atleast one Sub Category required"), 
      website_link: yup.string().url().label('Website Link').required(),
      price: yup.number().required().label("Price"),
      price_inr: yup.number().typeError('Price must be a number').notRequired().label("Price"),
      coin_discount: yup.number().typeError('Coin Discount accepts only number').min(0, 'Cannot be less than 0').required().label("Coin Discount").max(100),
      // equivalent_coins: yup.number().min(0, 'Cannot be less than 0').required().label("Equivalent Coins"),
      equivalent_coins: yup.number().when("coin_discount", {
        is: (coin_discount) => coin_discount >0,
        then: yup.number().typeError('Equivalent Coins must be a number').label('Equivalent Coins').min(1,"Minimum value should be one").required('Coin should be there')}),
      description: yup.string().required().label("Description").max(1000),
      image: yup
        .array()
        .label("Product Image")
        // @ts-ignore
        // .maxFileSize("Max allowed file size is 500kB", 500*1024)
        // @ts-ignore
        .maxTotalFileSize("Max allowed total file size is 20Mb", 20*1024*1024),
    })

  async componentDidMount() {
    const loadInitData = async()=>{
      let authToken = await getStorageData('authToken');
    const id = this.props.navigation.getParam('id')
    if(id){
      const msg = sendAPIRequest(configJSON.getProductByIdEndpoint.replace(':pid', id), {
              method: 'GET',
              headers: { 'token': authToken }
            })
          this.setState({
            getProductDetailCallId: msg 
          })
    } else {
      this.generateJobIdCallId = sendAPIRequest(configJSON.generateProductId, {
        method: 'GET',
        headers: { 'token': authToken }
      })
    }
  }
  let authToken = await getStorageData('authToken');
      this.setState({token: authToken},()=> {
        loadInitData()
      });
  }



  setSelectedMyTab = (idx: number) => this.setState({selectedMyTab: idx})
  hideModal = ()=> this.props.navigation.goBack()

  handleCreateProductSubmit = (data: any) => {
    console.log("update data ", data);
    let { product_id_1="", product_id_2="", image="", uploaded_image=[], preference_ids=[], category_ids=[], sub_category_ids=[],  ...reqData} = data
    reqData['product_id'] = `${product_id_1}`
    reqData['product_random_id'] = `${product_id_2}`
    const isUpdate = this.props.navigation.getParam('id')
    if(isUpdate){
    reqData['product_unique_id'] = `${product_id_1}-${product_id_2}`
    console.log("inside if");
    if(this.props.navigation.getParam('id')) {
      (['id', 'account_id', 'product_unique_id', 'name_of_user', 'total_likes', 'is_liked', 'like_id', 'total_comments', 'shares', 'created_at', 'is_favourite', 'favourite_id', 'created_by', 'product_id', 'product_random_id', 'images' ]).forEach((key: string)=>{delete reqData[key]})
    }
    const body = new FormData()
    preference_ids.forEach((id: any)=>body.append('data[attributes][preference_ids][]', id))
    category_ids.forEach((id: any)=>body.append('data[attributes][category_ids][]', id))
    sub_category_ids.forEach((id: any) => body.append('data[attributes][sub_category_ids][]', id))
    uploaded_image.forEach((im: any)=> body.append('data[attributes][images_ids][]', im.id))
    if(Array.isArray(image)) 
    image.forEach((im: any) => body.append('data[attributes][images][]', im))

      Object.entries(reqData).forEach(data => body.append(`data[attributes][${data[0]}]`, data[1] as string))

      const productId = this.props.navigation.getParam('id')
      {
        if((data.uploaded_image.length > 0 && data.image) || data.image || data.uploaded_image.length > 0){
            console.log("inside if 3")
            this.productCreateCallId = sendAPIRequest(configJSON.productUpdateEndpoint.replace(':id', productId), 
              {
                method: 'PUT', 
                body, 
                headers: { token: this.state.token }
              }
            )
            this.setState({ submittingForm: true })
        }
        else {
          console.log("do something");
          toast.warn("Atleast one image should be uploaded");
        }
      }
    }
    else {
      console.log("in else")
    reqData['product_unique_id'] = `${product_id_1}-${product_id_2}`
    reqData['category_id'] = 1
    reqData['preference_ids'] = `[${preference_ids}]`
    reqData['category_ids'] = `[${category_ids}]`
    reqData['sub_category_ids'] = `[${sub_category_ids}]`
    const body = new FormData()
    for(const item of Object.entries(reqData)){
      body.append(`data[attributes][${item[0]}]`, item[1] as string)
    }
  
    for(const img of image) {
      body.append(`data[attributes][images][]`, img)
    }

    this.productCreateCallId = sendAPIRequest(configJSON.productCreateAPIEndpoint, 
      {
        method: 'POST', 
        body, 
        headers: { token: this.state.token }
      }
    )
    this.setState({ submittingForm: true })
    }
  }
  
  // Customizable Area End
  
}
