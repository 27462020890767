import React from 'react'
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { esgPathRecords, sendAPIRequest } from '../../../components/src/utils';
import { toast } from 'react-toastify';
import { getStorageData } from '../../../framework/src/Utilities';
interface ESGTypes {
  id: number;
  updated_at: string;
  email: string;
  ability_card: string;
  profile_picture: string | null;
  card_serial_number: number;
  card_banner: string;
  user_name: string | null;
  company_name: string | null;
  ability_type: string;
  created_at: string;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtSavedValue: string;
  tableRowId:any;
  openModal:boolean;
  riskUrl: string,
  projectUrl: string,
  projectActivityUrl: string,
  projectMilestoneUrl: string,
  riskAndOpportunityList: any[];
  projectsList: any[];
  projectMilestoneList: any[];
  projectActivityList: any[];
  currentPageOfRisk: number;
  overlayLoaderForRisk:boolean;
  overlayLoaderForActivity:boolean;
  overlayLoaderForReport:boolean;
  overlayLoaderForMilestone:boolean;
  totalPageOfRisk: number;
  currentPageOfProjects: number;
  totalPageOfProjects: number;
  currentPageOfProjectMilestone: number;
  totalPageOfProjectMilestone: number;
  currentPageOfProjectActivity: number;
  totalPageOfProjectActivity: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UpdatedReportsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  riskAndOpportunityData: any;
  projectData: any;
  projectActivityData: any;
  projectMilestoneData: any;
  downloadReportOfRiskUrl: any;
  downloadReportOfProjectsUrl: any;
  downloadReportOfProjectMilestoneUrl: any;
  downloadReportOfProjectActivityUrl: any;
  showEsgCallId:string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtSavedValue: "",
      tableRowId:null,
      openModal:false,
      riskUrl: "",
      overlayLoaderForRisk:false,
      overlayLoaderForActivity:false,
      overlayLoaderForReport:false,
      overlayLoaderForMilestone:false,
      projectUrl: "",
      projectActivityUrl: "",
      projectMilestoneUrl: "",
      riskAndOpportunityList: [],
      projectsList: [],
      projectMilestoneList: [],
      projectActivityList: [],
      currentPageOfRisk: 1,
      totalPageOfRisk: 1,
      currentPageOfProjects: 1,
      totalPageOfProjects: 1,
      currentPageOfProjectMilestone: 1,
      totalPageOfProjectMilestone: 1,
      currentPageOfProjectActivity: 1,
      totalPageOfProjectActivity: 1
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const authToken= await getStorageData('authToken');
    if (authToken == null) {
      this.props.navigation.navigate('Home')
    }
    const userStr = await getStorageData('user');
    const userData = JSON.parse(userStr);
    let licensedUser = userData?.role?.role_level < 5 || userData.user_type === "licensed_user"
    if(licensedUser){
      this.esgAccessAPI(authToken)
    }
    this.setState({ overlayLoaderForRisk:true,
      overlayLoaderForActivity:true,
      overlayLoaderForReport:true,
      overlayLoaderForMilestone:true})
    if(this.props.navigation.history.location.pathname == '/RiskOpportunityManagementReport'){
      this.getRiskAndOpportunityData(this.state.currentPageOfRisk)
      this.getDownloadReportOfRisk()
    }
    else if(this.props.navigation.history.location.pathname == '/ProjectReport'){
      this.getProjectData(this.state.currentPageOfProjects)
      this.getDownloadReportOfProjects()
    }
    else if(this.props.navigation.history.location.pathname =='/ProjectMileStoneReport'){
      this.getProjectMilestoneData(this.state.currentPageOfProjectMilestone)
      this.getDownloadReportOfProjectMilestone()
    }
    else if(this.props.navigation.history.location.pathname == '/ProjectActivity'){
      this.getProjectActivityData(this.state.currentPageOfProjectActivity)
      this.getDownloadReportOfProjectACtivity()
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage)); this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
      this.setState({ txtSavedValue: value });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", message);
      if (responseJson && responseJson.errors) {
        this.setState({ overlayLoaderForRisk:false,
          overlayLoaderForActivity:false,
          overlayLoaderForReport:false,
          overlayLoaderForMilestone:false})
        toast.error('Something went wrong')
      }
      else if (apiRequestCallId == this.riskAndOpportunityData) {
        this.setState({
          riskAndOpportunityList: responseJson?.objectives?.data,
          currentPageOfRisk: responseJson?.objectives?.meta?.pagination?.current_page,
          totalPageOfRisk: responseJson?.objectives?.meta?.pagination?.total_pages
        })
      }
      else if (apiRequestCallId == this.projectData) {
        this.setState({
          projectsList: responseJson?.projects?.data,
          currentPageOfProjects: responseJson?.projects?.meta?.pagination?.current_page,
          totalPageOfProjects: responseJson?.projects?.meta?.pagination?.total_pages
        })
      }
      else if (apiRequestCallId == this.projectMilestoneData) {
        this.setState({
          projectMilestoneList: responseJson?.milestones?.data,
          currentPageOfProjectMilestone: responseJson?.milestones?.meta?.pagination?.current_page,
          totalPageOfProjectMilestone: responseJson?.milestones?.meta?.pagination?.total_pages
        })
      }
      else if (apiRequestCallId == this.projectActivityData) {
        this.setState({
          projectActivityList: responseJson?.activities?.data,
          currentPageOfProjectActivity: responseJson?.activities?.meta?.pagination?.current_page,
          totalPageOfProjectActivity: responseJson?.activities?.meta?.pagination?.total_pages
        })
      }
      else if (apiRequestCallId == this.downloadReportOfRiskUrl) {
        this.setState({ riskUrl: responseJson.download_url,overlayLoaderForRisk:false })
      }
      else if (apiRequestCallId == this.downloadReportOfProjectActivityUrl) {
        this.setState({ projectActivityUrl: responseJson.download_url, overlayLoaderForActivity:false })
      }
      else if (apiRequestCallId == this.downloadReportOfProjectsUrl) {
        this.setState({ projectUrl: responseJson.download_url,overlayLoaderForReport:false })
      }
      else if (apiRequestCallId == this.downloadReportOfProjectMilestoneUrl) {
        this.setState({ projectMilestoneUrl: responseJson.download_url,overlayLoaderForMilestone:false })
      }
      else if(apiRequestCallId === this.showEsgCallId){
        this.handleEsgCards(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getRiskAndOpportunityData = async(page: any) => {
    const authToken= await getStorageData('authToken');

    this.riskAndOpportunityData = sendAPIRequest(`${configJSON.riskAndOpportunity}?per_page=${10}&page=${page}`, {
      method: "GET",
      headers: { 'token': authToken || '' }
    })
  }

  getProjectData = async(page: any) => {
    const authToken= await getStorageData('authToken');

    this.projectData = sendAPIRequest(`${configJSON.getProjects}?per_page=${10}&page=${page}`, {
      method: "GET",
      headers: { 'token': authToken || '' }
    })
  }

  getProjectMilestoneData = async(page: any) => {
    const authToken= await getStorageData('authToken');

    this.projectMilestoneData = sendAPIRequest(`${configJSON.getProjectsMileStone}?per_page=${10}&page=${page}`, {
      method: "GET",
      headers: { 'token': authToken || '' }
    })
  }

  getProjectActivityData = async(page: any) => {
    const authToken= await getStorageData('authToken');

    this.projectActivityData = sendAPIRequest(`${configJSON.getProjectsActivity}?per_page=${10}&page=${page}`, {
      method: "GET",
      headers: { 'token': authToken || '' }
    })
  }

  handlePageChangeOfRiskManagement = (event: any, value: number) => {
    this.setState({ currentPageOfRisk: value }, () => this.getRiskAndOpportunityData(value));
  }

  handlePageChangeOfProjects = (event: any, value: any) => {
    this.setState({ currentPageOfProjects: value }, () => this.getProjectData(value));
  }

  handlePageChangeOfProjectMileStone = (event: any, value: any) => {
    this.setState({ currentPageOfProjectMilestone: value }, () => this.getProjectMilestoneData(value));
  }

  handlePageChangeOfProjectActivity = (event: any, value: any) => {
    this.setState({ currentPageOfProjectActivity: value }, () => this.getProjectActivityData(value));
  }

  getDownloadReportOfRisk = async() => {
    const authToken= await getStorageData('authToken');

    this.downloadReportOfRiskUrl = sendAPIRequest(`${configJSON.downloadReportOfRisk}`, {
      method: "GET",
      headers: { 'token': authToken || '' }
    })
  }

  getDownloadReportOfProjects = async() => {
    const authToken= await getStorageData('authToken');

    this.downloadReportOfProjectsUrl = sendAPIRequest(`${configJSON.downloadReportOfProjects}`, {
      method: "GET",
      headers: { 'token': authToken || '' }
    })
  }

  getDownloadReportOfProjectMilestone = async() => {
    const authToken= await getStorageData('authToken');

    this.downloadReportOfProjectMilestoneUrl = sendAPIRequest(`${configJSON.downloadReportOfProjectMilestone}`, {
      method: "GET",
      headers: { 'token': authToken || '' }
    })
  }

  getDownloadReportOfProjectACtivity = async() => {
    const authToken= await getStorageData('authToken');

    this.downloadReportOfProjectActivityUrl = sendAPIRequest(`${configJSON.downloadReportOfProjectActivity}`, {
      method: "GET",
      headers: { 'token': authToken || '' }
    })
  }

  showPopup() {
    toast.error("No Report Found")
  }

  handleModalClose = () => {
    this.setState({openModal:false, tableRowId:null})
  }
  handleModalOpen = (value:any) => {
    this.setState({openModal:true, tableRowId:value})
  }
  
  esgAccessAPI = async (authToken:string) => {
    this.showEsgCallId = sendAPIRequest(`${configJSON.showAllCards}`, {
      method: "GET",
      headers: {token: authToken},
    })
  }

  navigationFunc = (route: string, props: Props) => {
    const DataMessageReport = new Message(getName(MessageEnum.NavigationMessage));
    DataMessageReport.addData(getName(MessageEnum.NavigationTargetMessage), route);
    DataMessageReport.addData(getName(MessageEnum.NavigationPropsMessage), props);   
    this.send(DataMessageReport);
  }
 
  handleEsgCards(esgCardsData:{
    access: {
      data: {
        id: string;
        attributes: ESGTypes
        type: string;
      }[];
    }}){
    let accessibilityESG = esgPathRecords(esgCardsData)
    if(!accessibilityESG){
      const warningMsg = "Sorry, You do not have access to this page"
      const backPageLink = "ActivityFeed"
      toast.warn(warningMsg)
      this.navigationFunc(backPageLink,this.props)   
    }
  }
  // Customizable Area End

}
