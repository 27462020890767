import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Job } from './types'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { sendAPIRequest } from '../../../components/src/utils'
import { sendNewJobCreatedMessage, sendJobUpdatedMessage } from './Messages'
import { getStorageData } from "../../../framework/src/Utilities";
yup.addMethod(yup.string, "phoneNumber", function () {
  const validationError = "Please enter a valid phone number";
  return this.test('test-name', validationError, function (value) {
    const indianNumberRegex = /^\+91\d{10}$/;
    const internationalNumberRegex = /^\+\d{5,15}$/;

    if (value?.startsWith("+91")) {
      if (!indianNumberRegex.test(value)) {
        return this.createError({ message: "Number starting with +91 must have 10 digits" });
      }
    } else if (!internationalNumberRegex.test(value)) {
      return this.createError({ message: validationError });
    }

    return true;
  });
});


yup.addMethod(yup.mixed, "maxFileSize", function(error: string, maxSize: number) {
  return this.test('test-name', error, function(file: File|undefined){
    if(!file) return true
    if(file.size>maxSize) return false
    return true
  })
})

yup.addMethod(yup.mixed, "fileType", function(fileTypes: string[], error: string){
  return this.test('test-name', error, function(file?: File){
    if(!file) return true
    return fileTypes.some(
      ft => (!!file.type.match(ft))
    )
  })
})

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  jobs: Job[];
  selectedJob?: Job;
  selectedMyTab: number;
  modal: 'apply' | 'create' | null;
  loadingJobs: boolean;
  filterQuery: string;
  randomJobId?: string;
  getJobDetailCallId?: string;
  job?: any;
  submittingForm?: boolean;
  removedAttachments: number[];
  removedImages: number[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateJobController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiJobsFilterCallId: string = ""
  apiCategoryListCallId: string = ""
  apiCreateJobCallId: string = ""
  apiApplyJobCallId: string = ""
  apiFavJobCallIds: string[] = []
  apiUnfavJobCallIds: string[] = []
  generateJobIdCallId: string = ""
  favCallIdToPostId:{[key: string]: string} = {}
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: '',
      jobs: [],
      selectedMyTab: 0,
      modal: null,
      loadingJobs: false,
      filterQuery: '',
      randomJobId: '',
      removedAttachments:[],
      removedImages:[],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson?.errors) {
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (responseJson) {
        this.handleApiResponse(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End

  }



  // Customizable Area Start
  handleAccountLoginSuccess(message: Message) {
    const value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
    this.showAlert("Change Value", "From: " + this.state.txtSavedValue + " To: " + value);
    this.setState({ txtSavedValue: value });
  }
  handleApiResponse(apiRequestCallId: string, responseJson: any) {
    if (apiRequestCallId === this.apiCreateJobCallId) {
      this.handleCreateJobApiResponse(responseJson);
    } else if (apiRequestCallId === this.apiApplyJobCallId) {
      this.handleApplyJobApiResponse(responseJson);
    } else if (this.apiFavJobCallIds.includes(apiRequestCallId)) {
      this.handleFavoriteJobApiResponse(apiRequestCallId, responseJson);
    } else if (apiRequestCallId === this.generateJobIdCallId) {
      this.setState({ randomJobId: responseJson.job_id });
    } else if (apiRequestCallId === this.state.getJobDetailCallId) {
      this.setState({ job: responseJson.data.attributes });
    }
  }
  handleCreateJobApiResponse(responseJson: any) {
    if (responseJson?.data?.attributes) {
      const newJob = responseJson.data.attributes;
      const isEditPage = this.props.navigation.getParam('id');
      if (isEditPage) {
        toast.success('Job Updated Successfully');
        sendJobUpdatedMessage(newJob);
      } else {
        toast.success('Job Created Successfully');
        sendNewJobCreatedMessage(newJob);
      }
      this.setState({ modal: null, submittingForm: true });
      this.props.navigation.goBack();
    }
  }
  
  handleApplyJobApiResponse(responseJson: any) {
    if (responseJson?.data?.attributes) {
      this.setState({ modal: null });
      toast.success('Applied for Job Successfully');
    }
  }
  
  handleFavoriteJobApiResponse(apiRequestCallId: string, responseJson: any) {
    const postId = this.favCallIdToPostId[apiRequestCallId];
    this.apiFavJobCallIds = this.apiFavJobCallIds.filter((x: any) => x !== apiRequestCallId);
    delete this.favCallIdToPostId[apiRequestCallId];
    if (responseJson.data) {
      const fav = { ...responseJson.data, ...responseJson.data?.attributes };
      const newPosts = this.state.jobs.map((post: any) => {
        if (String(post.id) === postId) {
          return { ...post, favourite_id: [...(post.favourite_id || []), fav] };
        }
        return { ...post };
      });
      this.setState({ jobs: newPosts });
    }
  }
  phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  urlRegExp = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm
  
  

  createFormSchema = yup.object({
    job_title: yup.string().label('Title').required().max(255),
    job_id_1: yup.string().label('Unique ID').required().matches(/^[a-zA-Z0-9]+$/, "Only alphabets and numbers are accepted"),
    job_id_2: yup.string().label('Unique ID').required().matches(/^[a-zA-Z0-9]+$/, "Only alphabets and numbers are accepted"),
    preference_ids: yup.array().min(1, 'Select atleast one Preference'),
    category_ids: yup.array().min(1, 'Select atleast one Category'),
    sub_category_ids: yup.array().min(1, 'Select atleast one Sub-Category'),
    job_type: yup.array().min(1, 'Select atleast one option'),
    description: yup.string().label('Description').required().max(1000),
    reference_link: yup.string().label('Reference Link').matches(this.urlRegExp, 'Enter a valid URL'),
    location: yup.string().label('Location').required(),
    experience_required: yup.number().typeError("Experience must be a number").label('Experience').required(),
    business_name: yup.string().label('Business Name').required().matches(/^[a-zA-Z.& ]+$/, "Only alphabets,space,. & are accepted"),
    salary_type: yup.number().label('Salary Type').required().min(0, "Please select a Salary Type"),
    min_salary_in_dollar: yup.number().label('Minimum salary').required().positive().lessThan(yup.ref('max_salary_in_dollar'), 'Should be less than max salary($)'),
    min_salary_in_rs: yup.number().label('Minimum salary').notRequired().positive().lessThan(yup.ref('max_salary_in_rs'), 'Should be less than max salary(rs)'),
    max_salary_in_dollar: yup.number().label('Maximum Salary').required().positive().moreThan(yup.ref('min_salary_in_dollar'), "Should be greater than min salary($)"),
    max_salary_in_rs: yup.number().label('Maximum Salary').notRequired().positive().moreThan(yup.ref('min_salary_in_rs'), 'Should be greater than min salary(rs)'),
    attachmentss: yup.mixed()
      // @ts-ignore
      .maxFileSize("Image cannot be more than 500kb", 500*1024)
      // @ts-ignore
      // .fileType(['image/jpeg', 'image/jpg', 'image/png'], 'Image must be a jpeg or png file'),
  })


  async componentDidMount() {
    // @todo call account data block for token
    let authToken = await getStorageData('authToken');
    
    const loadInitData = ()=>{
      const id = this.props.navigation.getParam('id')

      if(id){
        const msg = sendAPIRequest(configJSON.jobDetailEndpoint.replace(':id', id), {
                method: 'GET',
                headers: { 'token': authToken }
              })
            this.setState({
              getJobDetailCallId: msg 
            })
      } else
        this.generateJobIdCallId = sendAPIRequest(configJSON.generateJobId, {
          method: 'GET',
          headers: { 'token': authToken }
        })
    }
    this.setState({token: authToken}, () => {
    loadInitData()
    })  
  }
  

  sendGetRequest = (endpoint: string, ) => {
    const headers = {
      "Content-Type": "application/json",
      "token": this.state.token,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    const callId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return callId;
  }
  
  showCreateModal = () => { this.setState({ modal: 'create' }) }

  hideModal = () => { this.props.navigation.goBack() }

  handleCreateJobSubmit = (data: any) => {
    let { job_id_1="", job_id_2="", attachments="", job_type=[], image="", preference_ids=[], category_ids=[], sub_category_ids=[],uploaded_image=[], ...reqData } = {  ...data }
    reqData['job_id'] = `${job_id_1}`
    reqData['job_random_id'] = `${job_id_2}`
    // reqData['job_type'] = job_type.join(' , ')
    if(this.props.navigation.getParam('id')) {
      (['id', 'account_id', 'job_unique_id', 'name_of_user', 'total_likes', 'is_liked', 'like_id', 'total_comments', 'shares', 'created_at', 'is_favourite', 'favourite_id', 'created_by', 'job_id', 'job_random_id', 'images' ]).forEach((key: string)=>{delete reqData[key]})
    }
    const body = new FormData()
    job_type.forEach((jtp: any)=> body.append('job[job_type][]', jtp))

    preference_ids.forEach((id: any)=>body.append('job[preference_ids][]', id))
    category_ids.forEach((id: any)=>body.append('job[category_ids][]', id))
    sub_category_ids.forEach((id: any) => body.append('job[sub_category_ids][]', id))
    console.log({jobEditData: image, attachments})
    uploaded_image?.length>0 && uploaded_image.forEach((im: any)=> body.append('job[images_ids][]', im.id))
    if(Array.isArray(image)) 
      image.forEach((im: any) => body.append('job[images][]', im))
    if (attachments?.length>0) {
      attachments.forEach((a: any) => {
        if (!a.id) {
          body.append(`job[attachments][]`, a as string);
        } else {
          body.append(`job[attachments_ids][]`, a.id as string);
        }
      });
    }    
    Object.entries(reqData).forEach(data => body.append(`job[${data[0]}]`, data[1] as string))
    body.append("delete_image_ids",JSON.stringify(this.state.removedImages))
    body.append("attacment_image_ids",JSON.stringify(this.state.removedAttachments))
    
    const headers = {
      "token": this.state.token,
    }
    let url = configJSON.jobCreateAPIEndpoint
    let method = 'POST'
    const jobId = this.props.navigation.getParam('id')
    if(jobId) {
      url = configJSON.jobDetailEndpoint.replace(':id', jobId)
      method = 'PATCH';
      
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.apiCreateJobCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    this.setState({ submittingForm: true })
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  removeAttachments = (attachmentId:number) => {
    if(attachmentId){
        this.setState(prevState => ({
            removedAttachments: [...prevState.removedAttachments, attachmentId]
        }))
    }
  }
  removeUploadedImages = (imageId:number) => {
    if(imageId){
        this.setState(prevState => ({
          removedImages: [...prevState.removedImages, imageId]
        }))
    }
  }
  getJobIds(): [string, string] {
    const { first_name = '', last_name = '' } = this.context;
    const { job } = this.state;
    
    let job_id_1 = '';
    let job_id_2 = '';
    if (job && job.job_unique_id) {
      const [id1, id2] = job.job_unique_id.split('-');
      job_id_1 = id1 || '';
      job_id_2 = id2 || '';
    } else {
      job_id_1 = `${first_name.slice(0, 3)}${last_name.slice(0, 2)}`.toUpperCase();
      job_id_2 = this.state.randomJobId || '-----';
    }
  
    return [job_id_1, job_id_2];
  }
  
  // Customizable Area End
  
}
