import React from "react";

// Customizable Area Start
import { MenuItem, Divider, Typography, Card,
  CardHeader,
  CardContent,
  Grid,
  IconButton,
  Button,
  Menu,
  Avatar,InputAdornment} from '@material-ui/core'
import { styled } from '@material-ui/styles'
import { Formik } from 'formik'

import DashboardLayout from '../../../components/src/DashboardLayout.web'
import MarketplaceTopbar from '../../../components/src/MarketplaceTopbar.web'
import FormModal, { InputRow, MInput, MSelect, MCountedTextArea, MDatePicker } from './Components.web'
import Flex from '../../../components/src/Flex.web'
import { CommonActions } from '../../../components/src/PostCard.web'
import DiscountCard from '../../../components/src/DiscountCard.web'
import { iconThumbGreen, OyoAvatar } from './assets'
import { commentCount, MoreHorizIcon } from '../../ActivityFeed/src/assets'
import UserContext from "../../../components/src/UserProvider";
import FilterPosts from '../../filteritems/src/FilterPosts.web'
import { LoadingSpinner } from '../../../components/src/LoadingSpinner.web'
import { Link } from 'react-router-dom';
import PlusMorePopup from '../../../components/src/PlusMorePopup.web'
import LikeAPost from '../../LikeAPost/src/LikeAPost.web'
import Moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component';
import DeleteModal from '../../../components/src/DeleteModal.web'
import Maps from "../../maps/src/Maps.web"
import PreferenceCatSubCat from '../../categoriessubcategories/src/PreferenceCatSubCat.web'
// @ts-ignore
import classes from './CfCourseListing5Web.module.css'

interface ApplyFormValues {
  name: string;
  email: string;
  phone_number: string;
  location: string;
  description : string;
}

// Customizable Area End

import CfCourseListing5Controller, {
  Props
} from "./CfCourseListing5Controller";

export default class CfCourseListing5 extends CfCourseListing5Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  createCourseModal = () => {
    const user = this.context
    console.log(user, "user context")
    const course_id_1 = `${user.first_name.slice(0,3)}${user.last_name.slice(0, 2)}`.toUpperCase()
    const course_id_2 = this.state.courseID 
    const initialValues = { course_id_1, course_id_2, course_name: '', course_type: -1,
      organization: '', price: '', price_Usd: '', coin_discount: '0',equivalent_coins: '0', description: '', mode: '',  start_date: this.state.startCourseDate || null, end_date: this.state.endCourseDate || null, eligibility: '', preference_ids: [], category_ids: [], sub_category_ids: []
    }

    if(this.state.modal !== 'create') return <></>
    return <Formik initialValues={initialValues} onSubmit={this.handleCreateFormSubmit} validationSchema={this.createFormSchema} >
      {({ values, errors }: any) => (
      <div className={ classes.modalTopCls }>
      <FormModal headerTitle={<b>Create Course</b>} onClose={this.hideModal} onSubmit={this.handleCreateFormSubmit}>
        <InputRow label="Course ID*" input={
          <IDWrapper>
            <Flex2>
              <MInput name="course_id_1" disabled placeholder="Course ID"/>
            </Flex2>
            <Flex2>
              <MInput name="course_id_2" disabled placeholder="Course ID" />
            </Flex2>
          </IDWrapper>
        } />
        <InputRow label="Course Name*" input={
            <MInput name="course_name" placeholder="Enter course name" />
        } />

        <PreferenceCatSubCat {...this.props} />

        <InputRow label="Course Type*" input={
          <MSelect defaultValue={-1} name="course_type" placeholder="Course" style={{ paddingLeft: '10px'}}>
            <MenuItem disabled value={-1}>Select</MenuItem>
            <MenuItem value={0}>Degree</MenuItem>
            <MenuItem value={1}>Diploma</MenuItem>
            <MenuItem value={2}>Certification</MenuItem>
            <MenuItem value={3}>Short Team</MenuItem>
            <MenuItem value={4}>Executive Training</MenuItem>
          </MSelect>
        } />
        <InputRow label="Organisation*" input={
            <MInput name="organization" placeholder="Enter organization" />
        } />
        <InputRow label="Price*" rowClass={classes.rowPriceStyleClass} labelClass={classes.labelStyleClass} input={
          <div>
          <div >
           <MInput name="price_Usd"  {...dollarFieldProps} />
           </div>
           <div style={{ marginTop: '15px' }}>
           <MInput name="price" {...rupeeFieldProps} />
           </div>
           </div>
        } />
        <InputRow label="Discount % *" input={
            <MInput name="coin_discount" placeholder="0" />
        } />
        <InputRow label="Equivalent Coins*" input={
            <MInput name="equivalent_coins" placeholder="0" disabled={values.coin_discount <= 0}/>
        } />
        <InputRow label="Description*" input={
            <MCountedTextArea name="description" placeholder="Description" class={classes.rowTextStyleClass}/>
        } />
        <InputRow label="Mode*" input={
          <MSelect defaultValue={-1} name="mode" placeholder="Course" style={{ paddingLeft: '10px'}}>
            <MenuItem disabled value={-1}>Select</MenuItem>
            <MenuItem value={0}>Online - live</MenuItem>
            <MenuItem value={1}>Online - SelfPaced</MenuItem>
            <MenuItem value={2}>Offline</MenuItem>
          </MSelect>
        } />
        <InputRow label="Start Date*" input={
            <MDatePicker name="start_date" InputProps={{placeholder: "Enter Date"}} maxDate={this.state.endCourseDate || null} disablePast onChange={this.startDateChange} />
        } />
        <InputRow label="End Date*" input={
            <MDatePicker name="end_date" InputProps={{placeholder: "Enter Date"}} disablePast minDate={this.state.startCourseDate || null} onChange={this.endDateChange}/>
        } />
        <InputRow label="Eligibility*" rowClass={classes.rowElStyleClass} labelClass={classes.labelStyleClass} input={
            <MInput name="eligibility" placeholder="Enter eligibility" />
        } />
      </FormModal>
      </div>
      )}
    </Formik>
  }
  createEnquireModal = () => {
    if(this.state.modal !== 'enquire') return <></>
    const initialValues: ApplyFormValues = {
      name: '', email: '', phone_number: '', location: '', description: ''
    }
    const juid_split = this.state.selectedCourse?.course_unique_id.split('-') || ""
    const [course_unique_id, course_unique_id_2] = [juid_split[0], juid_split[1]]
    
    return <Formik initialValues={initialValues} onSubmit={this.handleEnquireFormSubmit} validationSchema={this.enquireFormSchema}  >
        <FormModal headerTitle={<b>Course Enquire</b>} onClose={this.hideModal}>
          {/* <InputRow label="Course ID" input={
            <MInput name="course_unique_id" disabled  />
          } /> */}

          <InputRow 
          label="Course ID" 
          input={<IDWrapper>
                  <MInput disabled name="course_unique_id" value={course_unique_id} />
                  <MInput disabled name="course_unique_id_2" value={course_unique_id_2} />
                </IDWrapper>} 
        />
           {/* <InputRow label="Course Name" input={
            <MInput name="course_name" disabled />
          } />  */}
          <InputRow label="Course Name" input={<><MInput name="course_name"  value={this.state.selectedCourse?.course_name} disabled /></>} />


          <InputRow label="Name*" input={
            <MInput name="name" placeholder="Enter full name" />
          } />
          <InputRow label="Email Id*" input={
            <MInput name="email" placeholder="Enter Email" type="email" />
          } />
          <InputRow label="Phone Number*" input={
            <MInput name="phone_number" type="text" placeholder="Enter phone number" />
          } />
          <FormHeading variant="subtitle1" gutterBottom component="div">
            Location
            <Typography variant="subtitle1" component="span" style={{ fontWeight: "bold" }}>
              *
            </Typography>
          </FormHeading>
            {/* @ts-ignore */}
                <Maps {...this.props}
              customBorder='1px solid #E0E0E0'
              input
            />
          <InputRow label="Description*" input={
            <MCountedTextArea name="description" placeholder="Description" class={classes.rowTextStyleClass}/>
          } />
        </FormModal>
      </Formik>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { navigation } = this.props
    const courses = this.state.courses
    const enquireModal = this.createEnquireModal()
    const filterMenu = <FilterPosts filterAll={this.onFilterChange} onQueryChange={this.onFilterQueryChange} favouriteable_type="BxBlockCfcourselisting5::Course" id={this.props.id} navigation={this.props.navigation} />
    const path = navigation.history.location.pathname
    
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {enquireModal}
        <DashboardLayout token={this.token || ''} navigation={this.props.navigation}>
          <MarketplaceTopbar filters={filterMenu} userRole={this.context.role.role_level} buttonMinWidth="200px" onCreate={this.createCourse} selectedTab={4} onTabChange={()=>{}} path = {path} />
          
          {!this.state.loadingCourses && this.state.courses.length === 0 && <Typography style={{padding: '50px 0'}} align="center" variant="h5">No Match Found</Typography>}
          {this.state.loadingCourses && <LoadingSpinner />}
          {this.state.deleteCourseId && <DeleteModal label="Delete this course?" onClose={this.closeDeleteModal} onSubmit={this.handleCourseDeleteConfirm} />}
      <Menu
        id="threedot-menu"
        anchorEl={this.state.threedotAnchorEl}
        anchorOrigin={{
          vertical: 'bottom', 
          horizontal: 'left'
        }}
        keepMounted
        open={Boolean(this.state.threedotAnchorEl)}
        onClose={this.handleThreeDotClose}
      >
        <MenuItem
          id="report-listing"
          disabled={this.state.currentCourse?.created_by?.id == this.context.id}
          onClick={this.handleCourseReport}>Report This Listing</MenuItem>
        <MenuItem
          disabled={!this.state.currentCourse?.is_editable}
          onClick={this.handleCourseEdit}
        >
          Edit
        </MenuItem>
        <MenuItem
          disabled={!this.state.currentCourse?.is_deletable}
          onClick={this.handleCourseDelete}
        >
          Delete
        </MenuItem>
        <MenuItem
          id="raise-request"
          disabled={this.state.currentCourse?.created_by?.id == this.context.id}
          onClick={this.handleRaiseRequest}
        >
          Raise A Request
        </MenuItem>
      </Menu>
          <InfiniteScroll
          data-test-id="infiniteScrollId"
        dataLength={courses.length} //This is important field to render the next data
        next={this.loadNextPage}
        hasMore={(!!this.state.pagination?.next_page) && !this.state.loadingCourses}
        // Show only when this.state.loadingPosts component is not rendered
        loader={!(!this.state.loadingCourses && courses?.length === 0) && <LoadingSpinner />}
      >
          { courses.map(c=><RoundedCard style={{ padding: '0' }} key={c.id} variant="outlined">
          <CardHeader
              style={{ padding: '25px 20px 15px 20px' }}
              avatar={<Link to={`user/${c?.created_by?.id}`}><Avatar style={{ width: 55.26, height: 51.87 }} src={c.created_by?.profile_pic || OyoAvatar} onClick={() => navigation.navigate(`user/${c?.created_by?.id}`)} /></Link>}
              title={<span style={{ fontWeight: 600, marginBottom: '4px', fontSize: "1.021rem", maxWidth: '30ch', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block', textTransform: 'capitalize' }}><Link to={`/user/${c?.created_by?.id}`}>{c.created_by?.name}</Link></span>}
              subheader={<Grid style={{ gap: '10px' }} container alignItems="center">
                <span style={{ textTransform: 'capitalize', fontSize: '0.75rem' }}>{c.created_by?.location}</span>
                <Divider orientation="vertical" flexItem />
                <span style={{ fontSize: '0.75rem' }}>{c.created_at?.replace(/about/g, '')}</span>
              </Grid>}

              // @todo this is scheduled for later release
              action={<div onClick={this.openThreeDot} data-id={c.id}>
              <IconButton style={{ backgroundColor: 'transparent' }} disableFocusRipple disableRipple>
                <img src={MoreHorizIcon} width="30px" />
              </IconButton>
              </div>}

            />

            <Divider />
            <CardContent style={{ padding: '16px 30px' }}>
              <FlexSpaceBetween style={{ padding: '0', alignItems: 'start' }}>
               
                <div style={{ flex: 1 }}>
                  <CourseContent>
                    Course ID :
                  </CourseContent>
                  <MR5 id='course-unique-id'>{c.course_unique_id}</MR5>
                </div>
                <div style={{ flex: 1 }}>
                  <CourseContent>
                    Category :
                  </CourseContent>
                  <MR5>{c.categories ? c.categories[0] : ''}</MR5>
                  {c.categories?.length > 1 && <PlusMorePopup heading="Category" listItems={c.categories}>
                    +{c.categories?.length - 1}
                  </PlusMorePopup>}
                </div>
                <div style={{ flex: 1 }}>
                  <CourseContent>
                    Sub Category :
                  </CourseContent>
                  <MR5>{c.sub_categories ? c.sub_categories[0] : ''}</MR5>
                  {c.sub_categories?.length > 1 && <PlusMorePopup heading="Sub Category" listItems={c.sub_categories}>
                    +{c.sub_categories?.length - 1}
                  </PlusMorePopup>}
                </div>
              </FlexSpaceBetween>
              <FlexSpaceBetween style={{ padding: '0', alignItems: 'start', marginTop: '15px' }}>
                <div style={{ flex: 1 }}>
                  <CourseContent>
                  Start Date :
                  </CourseContent>
                  <MR5>{c.start_date ? Moment(c.start_date).format('DD/MM/YYYY') : ''}</MR5>
                </div>
                <div style={{ flex: 1 }}>
                  <CourseContent>
                  End Date :
                  </CourseContent>
                  <MR5>{c.end_date ? Moment(c.end_date).format('DD/MM/YYYY') : ''}</MR5>
                </div>
                <div style={{ flex: 1 }}>
                  <CourseContent>
                    Mode :
                  </CourseContent>
                  <MR5>{c.mode.replace('_', ' ')}</MR5>
                </div>
               
              </FlexSpaceBetween>
              <FlexSpaceBetween style={{ padding: '0', alignItems: 'start', marginTop: '15px' }}>
              <div style={{ flex: 1 }}>
                  <CourseContent>
                   Course Type :
                  </CourseContent>
                  <MR5>{c.course_type.replace('_', ' ')}</MR5>
                </div>
                <div style={{ flex: 1 }}>
                  <CourseContent>
                  Organization :
                  </CourseContent>
                  <MR5>{c.organization.length > 70 ? `${c.organization.substring(0, 70)}...` : c.organization}</MR5>
                </div>
                <div style={{ flex: 1 }}>
                  <CourseContent>
                    Eligibility :
                  </CourseContent>
                  <MR5>{c.eligibility.length > 70 ? `${c.eligibility.substring(0, 70)}...` : c.eligibility}</MR5>
                </div>
                
              </FlexSpaceBetween>
            </CardContent>
            <Divider />
            <CardContent>
               <Flex col py="10px" gap="20px" style={{ padding: '0px 15px 15px' }}>
                  <Flex id="course-wrapper" col onClick={this.goToCourse(c.id)}>
                    
                    <Typography style={{fontSize: '1rem', fontWeight: 'bold', wordWrap: 'break-word'}}>
                      {c.course_name.length > 80 ? `${c.course_name.substring(0, 80)}...` : c.course_name}
                    </Typography>
                    <Typography style={{ marginTop: '8px', marginBottom: '8px', color: '#1da509', fontWeight: 'bold', fontSize: '15px'}}>{ this.formatPrice(c.price_Usd, c.price)}</Typography>
                    <Typography color="textSecondary" style={{wordWrap: 'break-word'}}>{c.description.length > 455 ? `${c.description.substring(0, 455)}...` : c.description}</Typography>
                  
                  </Flex>
                  {Number(c.coin_discount)>0 && <DiscountCard coins={Number(c.equivalent_coins)} discount={Number(c.coin_discount)} />}
                  <Flex align="center" justify="space-between">
                    <div style={{ lineHeight: '30px', display: 'flex', gap: '10px', fontSize: '0.875rem', color: '#212529'}}>
                      <img src={iconThumbGreen} width="30" />
                      <span>{c.total_likes}</span>
                      <img src={commentCount} width="30" />
                      <span>{c.total_comments}</span>
                    </div>
                    { Number(this.context.id) !== Number(c.account_id) ? 
                    <ActionRoundedButton id="enquire-course" data-id={c.id} data-aid={c.account_id} data-cid={this.context.id} onClick={this.handleEnquireClick}>Enquire</ActionRoundedButton>
                    : '' }
                  </Flex>
                  
               </Flex>
               </CardContent>
               <Divider />
            <ActionWrapper style={{ padding: '0px 0px 16px'}}>
            <CommonActions 
              onStarClick={this.handleFavoriteClick(c.id)} 
              onShareClick={this.handleShareCourse(c)}
              isFavourite={c.is_favourite}  
              onCommentClick={this.goToCourse(c.id)}
              likeButton={
                <LikeAPost
                    navigation={this.props.navigation}
                    id=""
                    likeable_type="BxBlockCfcourselisting5::Course"
                    isLiked={c.is_liked}
                    post_id={String(c.id)}
                    like_id={c.like_id[0]?.id}
                    onLikeChange={this.onLikeChange}
                    onLikeIdUpdate={this.onLikeIdUpdate}
                />} 
            />
            </ActionWrapper>
          </RoundedCard>) }
          </InfiniteScroll>
        </DashboardLayout>
      {this.state.modal === 'create' && this.createCourseModal()}

      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
CfCourseListing5Controller.contextType = UserContext

const dollarFieldProps = {
  placeholder: "Enter",
  InputProps: {
    startAdornment: <InputAdornment position="start" style={{ paddingLeft: '6px' }}>$ <Divider style={{ marginLeft: '15px', border: '1px solid #f1f1f1', height: '44px'}} /></InputAdornment> 
  }
}

const rupeeFieldProps = {
  placeholder: "Enter",
  InputProps: {
    startAdornment: <InputAdornment position="start" style={{ paddingLeft: '6px' }}>₹ <Divider style={{ marginLeft: '15px', border: '1px solid #f1f1f1', height: '44px'}} /></InputAdornment>
  }
}

const RoundedCard = styled(Card)({
  borderRadius: '15px',
  padding: '10px 20px',
  margin: '20px 0px'
})

const IDWrapper = styled('div')({
  display: 'flex', 
  gap: '5px',
  '@media screen and (max-width: 480px)': {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  }
})

const Flex2 = styled(Flex)({
  flex: 1,
  '@media screen and (max-width: 480px)': {
    flex: 'none',
    width: '100%',
  }
})

const FlexSpaceBetween = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
  padding: '20px 0px',
  gap: '10px',
  width: '100%',
  '@media screen and (max-width: 480px)': {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
})

const ActionWrapper = styled('div')({
  padding: '0px 16px 16px',
  '@media screen and (max-width: 480)': {
    padding: '0px 0px 16px',
  },
})

const MR5 = styled('span')({
  marginRight: '5px',
  color: '#2a353f',
  textTransform: 'capitalize',
  wordBreak: 'break-all',
})

const CourseContent = styled('span')({
  fontWeight: 700, 
  fontSize: "15px", 
  marginRight: '5px'
})
const ActionRoundedButton = styled(Button)({
  color: '#2caa38',
  border: '2px solid #79bb74',
  borderRadius: '25px',
  padding: '5px 35px',
  textTransform: 'none',
  fontWeight: 600
 })

 const FormHeading = styled(Typography)({
  fontFamily: 'helveticaneue',
  color: '#404040',
  fontWeight: 500
}) as typeof Typography

const CreateHeader = styled('div')({
  fontSize: '24px',
  fontWeight: 500,
  fontFamily: 'HelveticaNeue'
})

// Customizable Area End
