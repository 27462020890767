import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    Modal,
    Backdrop,
    Grid,
    IconButton,
    TextField,
    Avatar,
    CircularProgress,
    ListItemText
} from "@material-ui/core";
import { Link } from 'react-router-dom'
import CloseIcon from "@material-ui/icons/Close";

import { styled } from '@material-ui/styles';
import SearchSelect, { Checkbox } from "../../../components/src/SearchSelect.web";
import { Formik, Form, useField } from 'formik';
import {
    iconUpload,
    UserProfile
} from './assets';
// @ts-ignore
import classes from './AddRequest.module.css';
import FormModal, { InputRow, MInput, MSelect, MFileDrop, MCountedTextArea, MCheckboxes, MTextArea } from '../../../components/src/FormModal.web';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Flex from '../../../components/src/Flex.web';
import { FormType } from "./enums";
import OverlayLoader from '../../../components/src/Loader.web'
import Attachments from '../../../components/src/Attachments.web'
import UploadButton from '../../../components/src/UploadButtonnew.web'

// Customizable Area End

import AddRequestController, { Props, configJSON } from "./AddRequestController.web";

export default class AddRequest extends AddRequestController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        console.log({ addRequestData: this.state })
        // Customizable Area End
        return (
            // Customizable Area Start
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",

                    }}
                    open={this.state.openModal}
                    onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            this.onCloseModal();
                        }
                    }}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            background: '#fff',
                            borderRadius: 8,
                            width: '650px',
                            position: "relative",
                            margin:'10px',
                        }} className="schedule_modal">
                        <Grid container spacing={0}>

                            {/* Modal Title */}
                            <ModalTitle item xs={12} >
                                <Typography style={{ paddingTop: '14px', fontWeight: 'bold' }}>
                                    {(this.state?.formData?.formTitle || (this.state?.isFormInitialized && 'ADD REQUEST'))}
                                    {this.state?.isViewRequest &&
                                        (<span style={{ color: '#afb2b4', fontWeight: 500, marginLeft: '14px' }}>
                                            { this.state.formData.is_completed  ? this.state?.formData?.status : 'Pending'}
                                        </span>)
                                    }
                                </Typography>
                                {/* Close Btn */}
                                <CloseIconButton onClick={this.onCloseModal}>
                                    <CloseIcon style={{}} />
                                </CloseIconButton>
                                {/* Close Btn END */}
                            </ModalTitle>
                            {/* Modal Title END */}

                            <ModelBody item xs={12} >
                                <Formik initialValues={this.initialValues} validationSchema={this.validationSchema} onSubmit={this.onSumitForm}>
                                    {({ errors, touched, setFieldValue, setTouched }) => (
                                        <RightFormWrapper translate="no">
                                            <div className={`${classes.linkInput}`} style={{ width: '100%' }}>
                                                <InputRow
                                                    label="Request Title*"
                                                    rowClass='formRow'
                                                    labelClass='formLabel'
                                                    input={<><MInput disabled={this.state.isViewRequest} name="title" placeholder="" inputProps={{ "data-testid": "request-title-input" }}/></>}
                                                />

                                                <InputRow
                                                    label='Request Type*'
                                                    rowClass='formRow'
                                                    labelClass='formLabel'
                                                    input={<Autocomplete
                                                        autoHighlight
                                                        value={this.state?.selectedRequestType}
                                                        onChange={(e, option) => { this.handleRequestTypeChange(e, option, setTouched, setFieldValue) }}
                                                        disabled={this.state.isViewRequest || this.isFieldDisabled('request_type_id')}
                                                        loading={!this.state?.requestTypes?.length}
                                                        options={this.state?.requestTypes}
                                                        getOptionLabel={(option: any) => option.title}
                                                        filterSelectedOptions
                                                        renderOption={(option, { selected }) => (
                                                            <>
                                                                {option.title}
                                                            </>
                                                        )}

                                                        renderInput={(params) => (
                                                            <MInput
                                                                {...params}
                                                                
                                                                name="request_type_id"
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (<>
                                                                        {!this.state?.requestTypes?.length && <CircularProgress color="inherit" size={20} />}
                                                                        {params.InputProps.endAdornment}
                                                                    </>),
                                                                }}
                                                                variant="outlined"
                                                                placeholder="Search"
                                                            />
                                                        )}
                                                    />
                                                    }
                                                />

                                                <InputRow
                                                    label={<Flex>Created By<sup>*</sup>
                                                        <Typography color="textSecondary"></Typography></Flex>}
                                                    rowClass='formRow'
                                                    labelClass='formLabel'
                                                    input={
                                                        <SearchSelect
                                                            selectProps={{ style: { minHeight: '56px' }, disabled: true }}
                                                            hasMore={!!this.state?.userPagination?.next_page}
                                                            nextPage={this.paginateUsersOptions}
                                                            loading={false}
                                                            onSearch={this.handleUserSearch}
                                                            allItems={(this.state?.created_by || [])}
                                                            items={(this.state?.created_by || [])}
                                                            name="created_by_id"
                                                            label={"Select User"}
                                                            placeholder="Select User"
                                                            SelectItem={SelectItemUser}
                                                            SelectedItem={SelectedItem}
                                                            getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                                                        />
                                                    }
                                                />


                                                <InputRow
                                                    label={<Flex>Request Owner<sup>*</sup>
                                                        <Typography color="textSecondary"></Typography></Flex>}
                                                    rowClass='formRow'
                                                    labelClass='formLabel'
                                                    input={
                                                        <SearchSelect
                                                            multiple={false}
                                                            selectProps={{ style: { minHeight: '56px' }, disabled: this.state.isViewRequest || this.isFieldDisabled('sender_id') }}
                                                            hasMore={!!this.state?.userPagination?.next_page}
                                                            nextPage={this.paginateUsersOptions}
                                                            loading={((!!this.state?.userSearchCallId) || (!!this.state?.userPaginateCallId)) && !!this.state?.requestOwnerLoading}
                                                            onSearch={this.handleRequestOwnerSearch}
                                                            allItems={(this.state?.users || [])}
                                                            items={(this.state?.users || [])}
                                                            name="sender_id"
                                                            label={"Sigle select"}
                                                            placeholder="Sigle select"
                                                            SelectItem={SelectItemRequestOwner}
                                                            SelectedItem={SelectedItem}
                                                            getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                                                        />
                                                    }
                                                />


                                                <InputRow
                                                    label={<Flex>Assign To<sup>*</sup>
                                                        <Typography color="textSecondary"></Typography></Flex>}
                                                    rowClass='formRow'
                                                    labelClass='formLabel'
                                                    input={
                                                        <AssignToSelect
                                                            selectProps={{ style: { minHeight: '56px' }, disabled: this.state.isViewRequest || this.isFieldDisabled('accounts') }}
                                                            hasMore={!!this.state?.userPagination?.next_page}
                                                            nextPage={this.paginateUsersOptions}
                                                            loading={((!!this.state?.userSearchCallId) || (!!this.state?.userPaginateCallId)) && !this.state?.requestOwnerLoading}
                                                            onSearch={this.handleUserSearch}
                                                            allItems={(this.state?.defaultUsers || []).concat(this.state?.users || [])}
                                                            items={(this.state?.defaultUsers || []).concat(this.state?.users || [])}
                                                            name="account_ids"
                                                            label={"Select Users"}
                                                            placeholder="Select Users"
                                                            SelectItem={SelectItemUser}
                                                            SelectedItem={SelectedItem}
                                                            getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                                                        />
                                                    }
                                                />

                                                <InputRow
                                                    label="Description*"
                                                    rowClass='formRow'
                                                    labelClass='formLabel'
                                                    input={
                                                        <>
                                                            <MInput
                                                                variant="outlined"
                                                                name="description"
                                                                disabled={this.state.isViewRequest}
                                                                multiline
                                                                maxRows={4}
                                                                minRows={4}
                                                                fullWidth
                                                            />
                                                        </>} />
                                                {this.state.formData?.reference && <InputRow 
                                                    label="Reference"
                                                    rowClass="formRow"
                                                    labelClass="formLabel"
                                                    input={this.state.formData.reference.map((r: any) => <Link style={{
                                                            textDecoration: 'underline',
                                                            fontSize: '14px',
                                                            color: 'rgb(0, 109, 3)'
                                                        }} to={r.link}>{r.text}</Link>)}
                                                 />}
                                                 
                                                {(this.state?.isFormInitialized && this.state.formType == FormType.ResponseRequest || this.state.formType == FormType.AcceptRejectRequest || this.state.formData.response) && <>
                                                    <InputRow
                                                        label="Response*"
                                                        rowClass='formRow'
                                                        labelClass='formLabel'
                                                        input={
                                                            <>
                                                                <MInput
                                                                    disabled={this.state.isViewResponce}
                                                                    variant="outlined"
                                                                    name="response"
                                                                    multiline
                                                                    maxRows={4}
                                                                    minRows={4}
                                                                    fullWidth
                                                                />
                                                            </>} />

                                                    <InputRow
                                                        label="Reference Link"
                                                        rowClass='formRow'
                                                        labelClass='formLabel'
                                                        input={<><MInput disabled={this.state.isViewResponce} name="reference_link" placeholder="" /></>}
                                                    />

                                                    {!this.state.isViewResponce && <>
                                                        <div style={{ margin: '10px 0' }}>
                                                            <UploadButton
                                                                maxTotalSize={10*1024*1024} 
                                                                name="attachments" 
                                                                setFileInState={this.setFilesArray} 
                                                                label="Upload Attachment"
                                                                multiple 
                                                            />
                                                        </div>
                                                    </>}
                                                    {this.state.formData?.response?.data?.attributes?.images_and_videos && <Attachments files={this.state.formData?.response?.data?.attributes?.images_and_videos} />}
                                                </>
                                                }

                                            </div>

                                            {((!this.state.isViewRequest && this.state?.isFormInitialized) || (!this.state.isViewResponce && this.state.formType === FormType.ResponseRequest)) && (
                                                <Grid item xs={12} style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'cender',
                                                    flexDirection: 'row',
                                                }}>
                                                    <DialogCancelButton onClick={this.onCloseModal}>
                                                        CANCEL
                                                    </DialogCancelButton>
                                                    <ShareNowButton color="primary" variant="contained" disableElevation type="submit">
                                                        SUBMIT
                                                    </ShareNowButton>
                                                </Grid>)
                                            }

                                            {(!this.state.isViewResponce && this.state.formType === FormType.AcceptRejectRequest) && (
                                                <Grid item xs={12} style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'cender',
                                                    flexDirection: 'row',
                                                }}>
                                                    <ShareNowButton 
                                                        disableElevation
                                                        color="primary" variant="outlined" type="submit" onClick={() =>
                                                        this.setState(prevState => ({
                                                            formData: { ...prevState.formData, status: 'Rejected' }
                                                        }))}>
                                                        REJECT
                                                    </ShareNowButton>
                                                    
                                                    <ShareNowButton 
                                                        disableElevation
                                                        color="primary" variant="contained" type="submit" onClick={() =>
                                                        this.setState(prevState => ({
                                                            formData: { ...prevState.formData, status: 'Accepted' }
                                                        }))
                                                    }>
                                                        ACCEPT
                                                    </ShareNowButton>
                                                </Grid>)
                                            }

                                        </RightFormWrapper>
                                    )}
                                </Formik>
                            </ModelBody>
                            <OverlayLoader loading={Boolean(this.state.respondRequestCallId || this.state.submitRequestCallId)} />
                        </Grid>
                    </Box>
                </Modal >
            </div >
            // Customizable Area End
        );
    }
}

// Customizable Area Start




const SelectItemUser = ({ item, value }: { item: any, value: any }) => {
    return <>
        <Grid container>
            <Grid item xs={12} style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <div className={`${classes.listLeftContainer}`}>
                    <SelectItemUserText >{`${item.first_name || ''} ${item.last_name || ''}`}</SelectItemUserText>
                    <Avatar className={`${classes.listAvatar}`} src={(item?.profile_pic || UserProfile)} aria-label="profile-pic">
                    </Avatar>
                </div>
                <span>{`${item.email}`}</span>
                <Checkbox checked={value?.indexOf?.(item.id) > -1} />
            </Grid>
        </Grid>
    </>
}

const SelectedItem = ({ items, value }: { items: any, value: any }) => {
    return <>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            {(items || []).map((selectedValue: any, i: any) => {
                return (<Typography key={i} align="left">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar className='ml10' style={{ width: '30px', height: '30px', }} src={selectedValue.profile_pic || UserProfile} aria-label="profile-pic"></Avatar>
                        <span className='ml5'>{`${selectedValue.first_name} ${selectedValue.last_name}` || ''}</span>
                    </div>
                </Typography>)
            })}
        </div>
    </>
}

const SelectItemRequestOwner = ({ item, value }: { item: any, value: any }) => {
    return <>
        <Grid container>
            <Grid item xs={12} style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
            }}>
                {/* <div className={`${classes.listLeftContainer}`}>
                    <SelectItemUserText >{`${item.first_name || ''} ${item.last_name || ''}`}</SelectItemUserText>
                    <Avatar className={`${classes.listAvatar}`} src={(item?.profile_pic || UserProfile)} aria-label="profile-pic">
                    </Avatar>
                </div> */}
                {/* <div style={{marginLeft: '40px',}}> */}
                <ListItemText style={{ flex: 2 }} disableTypography primary={
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar alt="Remy Sharp" src={item.profile_pic} style={{ width: "30px", height: "30px", marginRight: "10px" }} />
                    <Typography style={{ color: "#333232", fontWeight: "bold", fontSize: 12, width: '35ch', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {item.first_name} {item.last_name}
                    </Typography>
                </div>
                } />
                <Typography component={'div'} style={{ flex: 3, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {`${item.email}`}
                </Typography>
                {/* </div> */}
                <span></span>
            </Grid>
        </Grid>
    </>
}

const AssignToSelect  = (props: any)=>{
    const [field, meta, helpers] = useField('sender_id')
    const items = props.items.filter((i: any) => i.id !== field.value)
    const allItems = props.allItems.filter((i: any) => i.id !== field.value)
    return <SearchSelect
            selectProps={props.selectProps}
            hasMore={props.hasMore}
            nextPage={props.nextPage}
            loading={props.loading}
            onSearch={props.onSearch}
            allItems={allItems}
            items={items}
            name="account_ids"
            label={"Select Users"}
            placeholder="Select Users"
            SelectItem={SelectItemUser}
            SelectedItem={SelectedItem}
            getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
        />
}



const ModelBody = styled(Grid)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '15px 32px'
})


const SelectItemUserText = styled('span')({
    overflow: 'hidden',
    textOverflow: 'ellipsis'
})

const CloseIconButton = styled(IconButton)({
    background: '#676767',
    color: '#fcfcfc',
    width: '32px',
    height: '32px',
    '&:hover': {
        background: '#999',
        opacity: 0.9
    }
})

const DialogCancelButton = styled(Button)({
    color: '#FFF',
    background: '#D0D0D0',
    padding: '10px 18px 10px 18px',
    margin: '5px',
    '&:hover': {
        background: '#999',
        opacity: 0.9
    }
})

const ShareNowButton = styled(Button)({
    padding: '10px 18px 10px 18px',
    margin: '5px',
    '&:hover': {
        opacity: 0.9
    }
})

const ModalTitle = styled(Grid)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItem: 'center',
    flexDirection: 'row',
    width: '100%',
    borderBottom: '0.2px solid #cbc1c1',
    padding: '10px 20px 10px 32px'
})

const RightFormWrapper = styled(Form)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
    padding: '10px 0',
    overflowX: 'hidden',
    paddingRight: '10px',
    maxHeight: '500px'
})


// Customizable Area End
