import React from "react";

// Customizable Area Start
import {
  Grid,
  Divider,
  Typography,
  Modal,
  Backdrop,
  Menu,
  MenuItem,
  Box, 
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Avatar,
  Button,
  TextField,
  FormControl, OutlinedInput, InputAdornment
} from '@material-ui/core'
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import { styled } from '@material-ui/styles'
import { Formik } from 'formik'
import RoundedButton from '../../../components/src/RoundedButton.web'
import FormModal, { 
  InputRow, 
  MInput, 
  MCountedTextArea, 
  MFileDrop, 
  MTimePicker, 
  MDatePicker,
  MSelect, } from '../../../components/src/FormModal.web'
import CountedTextArea from '../../../components/src/CountedTextArea.web'
import DashboardLayout from '../../../components/src/DashboardLayout.web'
import FileDrop from '../../../components/src/FileDrop.web'
import SeeMore from '../../../components/src/SeeMore.web'
import { Link } from 'react-router-dom';
import LikeAPost from '../../LikeAPost/src/LikeAPost.web'
import PlusMorePopup from '../../../components/src/PlusMorePopup.web'
import MediaCarousel from '../../../components/src/MediaCarousel.web'
import UserContext from '../../../components/src/UserProvider'
import Attachments from '../../../components/src/Attachments.web'
import DeleteModal from '../../../components/src/DeleteModal.web'
import Picker from 'emoji-picker-react';
import ReportModal from '../../../components/src/ReportModal.web'
import Flex from '../../../components/src/Flex.web'
import PostCard, { CommonActions, Image } from '../../../components/src/PostCard.web'
import DiscountCard, { FilterSelect } from '../../../components/src/DiscountCard.web'
import Moment from 'moment'
import { iconLocation, iconThumbGreen,  SendIcon, EmogiIcon, LikedIcon, CloseIcon, LogoHex } from './assets'
//import ReportModal from '../../../components/src/ReportModal.web'

// Customizable Area End

import JobDetailsController, {
  Props,
  configJSON
} from "./JobDetailsController";

export default class JobDetails extends JobDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { navigation } = this.props
    let { courseData, courseCommentData } = this.state
    const job = courseData
    // Customizable Area End
    return (
      // Customizable Area Start
      
      <Wrapper>
      <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: '20px 40px',
      }}
      open={this.state.openModal}
      //onClose={}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
          timeout: 500,
      }}
  >
      <Box
          style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              background: '#fff',
              borderRadius: 8,
              width: '900px',
              position: "relative",
          }} className="schedule_modal">
            {/* <CloseButton onClick={this.onCloseModal}>
              <img src={CloseIcon} width="26px" />
            </CloseButton> */}
          <Grid container spacing={0}>
              <ModelBody item xs={12}>
              <RoundedCard style={{ padding: '0', margin: '0', maxHeight: 'calc(100vh - 50px)', overflow: 'scroll' }} key={courseData?.id} variant="outlined">
                <HeaderWrapper>
                <CardHeader
                style={{ padding: '25px 20px 15px 20px' }}
                avatar={<Link to={`user/${job?.created_by?.id}`}>
                  <Avatar style={{ width: 55.26, height: 51.87 }} src={job?.created_by?.profile_pic } onClick={() => navigation.navigate(`user/${job?.created_by?.id}`)} /></Link>}
                title={<span style={{ fontWeight: 600, marginBottom: '4px', fontSize: "1.021rem", maxWidth: '30ch', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block', textTransform: 'capitalize' }}><Link to={`user/${job?.created_by?.id}`}>{job.created_by?.name}</Link></span>}
                subheader={<Grid style={{ gap: '10px' }} container alignItems="center">
                  <span style={{ textTransform: 'capitalize', fontSize: '0.75rem' }}>{job.created_by?.location}</span>
                  <Divider orientation="vertical" flexItem />
                  <span style={{ fontSize: '0.75rem' }}>{job.created_at?.replaceAll('about', '')}</span>
                </Grid>}
            />
            <CloseButton onClick={this.onCloseModal}>
              <img src={CloseIcon} width="26px" />
            </CloseButton>
            </HeaderWrapper>
                  <Divider />
                  <FlexCol style={{ gap: '15px', padding: '15px' }}>
                    <FlexCol style={{ gap: '5px'}}>
                      <Typography variant="subtitle1" style={{ wordBreak: 'break-all' }}><b>{job.job_title}</b></Typography>
                      <div> <img src={iconLocation} width="20" style={{marginBottom: '-4px', marginRight: '5px'}} />{job.location} </div>
                      
                    </FlexCol>
                    <div style={{ display: 'flex', gap: '25px', flexWrap: 'wrap', marginTop: '15px' }}>
                      <div>
                        <b>Category:</b> {job.job_category && job.job_category[0]}
                          {job.job_category?.length>1&&(
                            <PlusMorePopup heading="Category" listItems={job.job_category}>
                              +{job.job_category?.length - 1}
                            </PlusMorePopup>)}
                      </div>
                      <div>
                        <b>Experience Required:</b> {job.experience_required}
                      </div>
                      <div>
                        <b>Job ID:</b> {job.job_unique_id}
                      </div>
                      <div>
                        <b>Salary Type:</b> {toTitleCase((job.salary_type||'').replaceAll('_', ' '))}
                      </div>
                      <div>
                        <b>Min Salary:</b> {this.formatSalary(job.min_salary_in_dollar,job.min_salary_in_rs)}
                      </div>
                      <div>
                        <b>Max Salary:</b> {this.formatSalary(job.max_salary_in_dollar,job.max_salary_in_rs)}
                      </div>
                      <div>
                        <b>Job Type:</b> {
                          job.job_type && job.job_type.map(
                              (jt: any) => toTitleCase(jt.replace('-', ' '))
                            ).join(', ')
                          }
                      </div>
                      <div>
                        <b>Business Name:</b> {job.business_name}
                      </div>
                      {job.reference_link && (
                        <Flex gap={10} align="center">
                            <b>Reference Link:</b>
                             <a style={{ color: "#48a15b",}} href={job.reference_link} target="blank">
                             <div style={{display:'flex', flexDirection:'column'}}>
                              <img src={LogoHex} width="25px" />
                                <InsertLinkIcon style={{color:'inherit', marginTop:'-16px', marginLeft:'8px'}}/>
                              </div>
                            </a>
                        </Flex>
                      )}
                      <div style={{ margin: "0 10%", width: "80%"}}>
                      {job.images && <MediaCarousel items={job.images} />}
                      </div>
                    </div>
                    
                    {job.image && <Image src={job.image}  />}
                  </FlexCol>
                  <FlexCol style={{padding: '0 15px'}}>
                    <SeeMore text={job.description}/>
                  </FlexCol>
                  <div style={{padding: '15px'}}>
                    <Attachments 
                      files={(job.attachments || []).map((j: any)=>({...j,}))} 
                    />  
                  </div>
                  <div style={{ padding: '0 15px 10px', margin: '-20px 0' }}>
                    <FlexSpaceBetween>
                      <div>
                        <Typography style={{fontSize: '1.22rem', fontWeight: 600}} variant="h6">
                          Looking for a job?:
                        </Typography >
                        <Typography color="textSecondary" variant="body2">
                          Apply for a job
                        </Typography>
                      </div>
                      <RoundedButton onClick={this.handleJobApply} data-id={job.id} style={{ padding: '8px 40px', marginRight: '35px'}}>Apply</RoundedButton>
                    </FlexSpaceBetween>
                  </div>
                  <Divider />
            <CommonActions 
              onStarClick={this.handleFavoriteClick} 
              onShareClick={this.handleShareCourse(courseData)}
              isFavourite={courseData?.is_favourite}
              isHideComment={true}
              likeButton={
                <LikeAPost
                    navigation={this.props.navigation}
                    id=""
                    likeable_type="BxBlockJoblisting2::Job"
                    isLiked={courseData?.is_liked}
                    post_id={String(courseData?.id)}
                    like_id={courseData?.like_id ? courseData?.like_id[0]?.id : ''}
                    onLikeChange={this.onLikeChange}
                    onLikeIdUpdate={this.onLikeIdUpdate}
                />} 
            />
                  <Divider style={{marginTop: '10px'}} />
                  
                  <DiscussSection>
                      <DiscussHeader>Discussion</DiscussHeader>
                      <div style={{ marginBottom: '40px'}}>
                      <CommentUserAvtar>
                          <Link to={`/user/${this.context?.id}`}><Avatar style={{ width: 35.26, height: 31.87 }} src={this.context?.profile_pic }/></Link>
                        </CommentUserAvtar>
                            <div style={{  width: 'calc(100% - 50px)', display: 'inline-block', boxSizing: 'border-box' }}>
                            
                              <OutlinedInput
                                id="outlined-adornment-weight"
                                value={ this.state.userComment }
                                onChange={this.handleCourseCommentChange}
                                placeholder="Comment your views"
                                endAdornment={<InputAdornment position="end"><BtnImg src={EmogiIcon} onClick={this.openEmojiComment } style={{width: '18px', paddingLeft: '10px', paddingRight: '10px'}}/>
                                <Divider orientation="vertical" style={{ height: '55px', color: '#fefefe'}} />
                                { this.state.userComment.length > 0 ? 
                                  <BtnImg src={SendIcon} onClick={() => this.handleCourseComment(courseData.id) } style={{width: '18px', paddingLeft: '25px', float: 'right', paddingRight: '2px', cursor:'pointer'}}/> 
                                  : <BtnImg src={SendIcon} style={{width: '18px', paddingLeft: '25px', float: 'right', paddingRight: '2px', opacity:0.5}}/>
                                }</InputAdornment>}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  'aria-label': 'weight',
                                }}
                                style={{ width: '100%'}}
                              />
                            </div>
                            { this.state.openCommentEmoji ? 
                             <Picker onEmojiClick={this.onCommentEmojiClick} pickerStyle={{ width: '100%' }}/> : "" }
                              
                      </div>
                      { courseCommentData?.map?.(p => <div style={{ marginBottom: '15px'}}>
                        <UserAvtar>
                          <Link to={`/user/${p?.account_id}`}><Avatar style={{ width: 35.26, height: 31.87 }} src={p?.profile_pic } onClick={() => navigation.navigate(`/user/${p?.account_id}`)} /></Link>
                        </UserAvtar>
                        <CommentDetail>
                            <AccountName>{ p?.account_name }</AccountName>
                            <CommentData>{ p?.content }</CommentData>
                        </CommentDetail>
                          <CardActions>
                            <FlexSpaceBetween style={{ padding: '0px 0px 5px 5px' }}>
                              <CardButtonWrapper>
                                <CardButton onClick={() => this.handleCommentLike(p)}>{ !p.is_like ? "Like": <div style={{ color: '#009c05'}}>Like</div>}</CardButton>
                                <div style={{ lineHeight: '2.5', color: '#d4d4d4' }}>|</div>
                                <CardButton onClick={() => this.handleSetReplyId(p.id)}>Reply</CardButton>
                              </CardButtonWrapper>
                              { p?.like_count ? 
                              <div style={{ display: 'flex', alignItems: 'center'}}>
                                <BtnImg src={LikedIcon} style={{width: '18px'}}/> 
                                <span style={{ paddingLeft: '3px', fontSize: '13px'}}>{ p?.like_count }</span> 
                              </div> : '' }
                            </FlexSpaceBetween>
                          </CardActions>
                          { this.state.reply_id === p.id ? 
                          <div style={{ marginTop: '15px', marginBottom: '40px', paddingLeft: '50px'}}>
                            <CommentUserAvtar>
                                <Link to={`/user/${this.context?.id}`}><Avatar style={{ width: 35.26, height: 31.87 }} src={this.context?.profile_pic }/></Link>
                              </CommentUserAvtar>
                              <div style={{  width: 'calc(100% - 50px)', display: 'inline-block', boxSizing: 'border-box' }}>
                            
                              <OutlinedInput
                                id="outlined-adornment-weight"
                                value={ this.state.userReply }
                                onChange={this.handleCourseReplyChange}
                                placeholder="Comment your views"
                                endAdornment={<InputAdornment position="end"><BtnImg src={EmogiIcon} onClick={this.openEmojiCommentReply } style={{width: '18px', paddingLeft: '10px'}}/> 
                                { this.state.userReply.length > 0 ? 
                                  <BtnImg src={SendIcon} onClick={() => this.handleReplyComment() } style={{width: '18px', paddingLeft: '25px', float: 'right', paddingRight: '2px', cursor:'pointer'}}/> 
                                  : <BtnImg src={SendIcon} style={{width: '18px', paddingLeft: '25px', float: 'right', paddingRight: '2px', opacity:0.5}}/>
                                }</InputAdornment>}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  'aria-label': 'weight',
                                }}
                                style={{ width: '100%'}}
                              />
                            </div>
                            { this.state.openCommentReplyEmoji ? 
                             <Picker onEmojiClick={this.onReplyEmojiClick} pickerStyle={{ width: '100%' }}/> : "" }
                            </div> : ''}
                            { p?.replies?.length > 0 && this.state.openReply != p.id ?
                            <div onClick={() => this.showReply(p.id) } style={{ width: 'calc(100% - 50px)', height: '8px', margin: '0px 50px 25px', borderBottom: '1px solid #cdcdcd', textAlign: 'center'}}>
                            <span style={{fontSize: '13px', backgroundColor: '#fff', padding: '0 30px', color: '#a9a9a9'}}>
                              View  { p?.replies?.length } replies
                            </span>
                          </div>
                          : '' }
                              { this.state.openReply == p.id  ? p?.replies?.map?.((r: any) => <div style={{ padding: '10px 0px 10px 50px' }}>
                                <UserAvtar>
                                  <Link to={`/user/${r?.account_id}`}><Avatar style={{ width: 35.26, height: 31.87 }} src={r?.profile_pic } onClick={() => navigation.navigate(`/user/${r?.account_id}`)} /></Link>
                                </UserAvtar>
                                <CommentDetail>
                                    <AccountName>{ r?.account_name }</AccountName>
                                    <CommentData>{ r?.content }</CommentData>
                                </CommentDetail>
                                </div>
                              ) : ''}
                      </div>) }

                      {
                        this.state.pagination?.next_page ? <div style={{ marginTop: '50px', marginBottom: '50px', width: '100%', textAlign: 'center', color: '#b9b9b9', fontSize: '15px', fontWeight: 'bold', cursor: 'pointer', backgroundColor: '#f0f0f0', padding: '15px 0px', borderRadius: '10px', borderColor: '#fefefe'}} onClick={this.loadMore}>Load more discussion</div> : '' 
                      }
                  </DiscussSection>
               </RoundedCard>
              </ModelBody>

          </Grid>
      </Box>
  </Modal >
  </Wrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
JobDetailsController.contextType = UserContext
const ModelBody = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'unset',
  flexDirection: 'column',
  padding: '0px'
})

function toTitleCase(str: string) {
  return str.replace(
    /\w\S*/g,
    function(txt: string) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

const FlexRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
})
const Flex1 = styled('div')({
  flex: 1,
})

const FlexCol = styled('div')({
  display: 'flex',
  flexDirection: 'column'
})


const SelectItemUserText = styled('span')({
  overflow: 'hidden',
  textOverflow: 'ellipsis'
})

const RoundedCard = styled(Card)({
  borderRadius: '15px',
  padding: '10px 20px',
  margin: '20px 0px'
})

const FlexSpaceBetween = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
  padding: '20px 0px',
  width: '100%',
})

const CardButton = styled(Button)({
  border: 'none',
  color: '#aaaaaa',
  fontWeight: 600,
  textTransform: 'none'
})

const CardButtonWrapper = styled('div')({
  display: 'flex',
  gap: '5px',
  padding: '0 20px'
})

const MR5 = styled('span')({
  marginRight: '5px',
  color: '#2a353f',
  textTransform: 'capitalize'
})
const BtnImg = styled('img')({
  width: 25
})
const PostMainContent = styled('div')({
  padding: '10px 0'
})

const HeaderWrapper = styled('div')({
  display: 'flex',
})

const CloseButton = styled(Button)({
  position: 'absolute',
  top: '20px',
  right: '0px',
  flex: 1,
})
const Wrapper = styled('div')({
  zIndex: 1010,
  minWidth: '100vw',
  minHeight: '100vh',
  background: 'rgba(0,0,0,0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const DiscussSection = styled('div')({
  padding: '16px 30px'
})

const AccountName = styled('div')({
  color: '#009c05',
  'font-weight': 'bold',
  'font-size': '13px'
})

const CommentData = styled('div')({
  width: '100%',
  'word-break': 'break-word',
  marginTop: '5px'
})

const DiscussHeader = styled('div')({
  fontSize: '18px',
  fontWeight: 'bold',
  padding: "15px 18px 25px",
  paddingLeft: 0
})

const UserAvtar = styled('div')({
  display: 'inline-block',
  width: '50px',
  height: '40px',
  verticalAlign: 'top',
  paddingTop: '3px'
})

const CommentUserAvtar = styled('div')({
  display: 'inline-block',
  width: '50px',
  height: '40px',
  verticalAlign: 'top',
  paddingTop: '12px'
})

const CommentDetail = styled('div')({
  display: 'inline-block',
  width: 'calc(100% - 50px)',
  boxSizing: 'border-box',
  padding: '10px 20px 20px',
  minHeight: '80px',
  border: '1px solid #e8e4e4',
  borderRadius: '5px',
  lineHeight: '1.3',
  textAlign: 'justify'
})

const CourseContent = styled('span')({
  fontWeight: 700, 
  fontSize: "15px", 
  marginRight: '5px'
})
// Customizable Area End
