import React from "react";


// Customizable Area Start
import { Formik } from 'formik'
import { styled } from '@material-ui/styles'
import { 
  MenuItem,
  Card,
  Divider, 
  Typography, 
  Popper,
  Paper,
  ClickAwayListener 
} from '@material-ui/core'
  import { LogoHex,} from './assets'
  import { commentCount } from '../../ActivityFeed/src/assets'
  import InsertLinkIcon from '@material-ui/icons/InsertLink';

import FilterPosts from '../../filteritems/src/FilterPosts.web'
import OverlayLoader from '../../../components/src/Loader.web'

import LikeAPost from '../../LikeAPost/src/LikeAPost.web'
import InfiniteScroll from 'react-infinite-scroll-component';

import DiscountCard from '../../../components/src/DiscountCard.web'
import PostCard, { CommonActions } from '../../../components/src/PostCard.web'
import DeleteModal from '../../../components/src/DeleteModal.web'
import UserContext from "../../../components/src/UserProvider";
import FormModal, {FRating} from '../../../components/src/FormModal.web'
import DashboardLayout from '../../../components/src/DashboardLayout.web'
import MarketplaceTopbar from '../../../components/src/MarketplaceTopbar.web'
import { LoadingSpinner } from '../../../components/src/LoadingSpinner.web'
import Rating from '../../../components/src/Rating.web'
import Flex from '../../../components/src/Flex.web'
import SeeMore from '../../../components/src/SeeMore.web'
import MediaCarousel from '../../../components/src/MediaCarousel.web'
// @ts-ignore

import { iconGreenThumb } from "../../Events2/src/assets";
// Customizable Area End

import CfProductListing11Controller, {Props} from "./CfProductListing11Controller";
export default class CfProductListing11 extends CfProductListing11Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  rateProductModal = () => {
    const product = this.state.selectedProduct
    if(this.state.modal !== 'rate') return <></>
    return <Formik initialValues={{}} onSubmit={this.handleRateProductSubmit}>
      <FormWrapper>
      <FormModal 
        modalStyle={{ height: '360px' }} 
        headerTitle="RATE PRODUCT" 
        submitLabel="Submit Review" 
        onClose={this.hideModal}>
        <Flex py="25px" gap="10px" col align="center">
          <Typography style={{ wordBreak: 'break-all' }} variant="h5">{product?.product_name}</Typography>
          <Typography>Product ID: {product?.product_unique_id}</Typography>
          <Typography color="textSecondary" variant="h5">Rate your product!</Typography>
          <FRating value={this.state.modalRating} onChange={this.setModalRating} />
        </Flex>
      </FormModal>
      </FormWrapper>
    </Formik>
  }

  threeDotMenu = () => {
    console.log({ threedotmenu: '', context: this.context, cp: this.state })
    return <>
      {this.state.deleteProductId && <DeleteModal onClose={this.closeDeleteModal} onSubmit={this.handleProductDeleteConfirm} />}
      <StyledPopper
        id="threedot-menu"
        open={Boolean(this.state.threedotAnchorEl)}
        anchorEl={this.state.threedotAnchorEl}
        role={undefined}
        transition
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={this.handleThreeDotClose}>
          <Paper>
            <MenuItem
              id='report-listing'
              disabled={this.state.currentProduct?.created_by?.id == this.context.id}
              onClick={this.handleProductReport}
              >Report This Listing
            </MenuItem>
            <MenuItem
              id='editProduct'
              disabled={!(this.state.currentProduct?.created_by?.id == this.context.id)}
              onClick={this.handleProductEdit}
            >
              Edit
            </MenuItem>
            <MenuItem
              id="deleteProduct"
              disabled={!(this.state.currentProduct?.created_by?.id == this.context.id)}
              onClick={this.handlePostDelete}
            >
              Delete
            </MenuItem>
            <MenuItem
              id="raiseRequest"
              disabled={this.state.currentProduct?.created_by?.id == this.context.id}
              onClick={this.handleRaiseRequest}
            >
              Raise A Request
            </MenuItem>
          </Paper>
        </ClickAwayListener>
      </StyledPopper>  
    </>
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const rate = this.rateProductModal()
    const {products} = this.state
    const path = this.props.navigation.history.location.pathname
    
    const filterMenu = <FilterPosts filterAll={this.onFilterChange} onQueryChange={this.onFilterQueryChange} favouriteable_type="BxBlockCfProductListing11::Product" id={this.props.id} navigation={this.props.navigation} />
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
      {rate}
      <OverlayLoader loading={!!this.state.loadingPosts} />
      <DashboardLayout token={this.state.token} navigation={this.props.navigation}>
        <MarketplaceTopbar 
        path={path}
        userRole={this.context.role.role_level}
         buttonMinWidth="200px" 
         filters={filterMenu} 
         onCreate={()=>this.props.navigation.history.push(`${window.location.pathname}/create-product`)} 
         selectedTab={this.state.selectedMyTab} 
         onTabChange={this.setSelectedMyTab} />
        {this.threeDotMenu()}
        {!this.state.loadingPosts && this.state.products.length === 0 && <Typography style={{padding: '50px 0'}} align="center" variant="h5">No Match Found</Typography>}
        <InfiniteScroll
          dataLength={products.length} //This is important field to render the next data
          next={this.loadNextPage}
          hasMore={(!!this.state.pagination?.next_page) && !this.state.loadingPosts}
          // Show only when this.state.loadingPosts component is not rendered
          loader={!this.state.loadingPosts && <LoadingSpinner />}
        >
        {
          products.map(p=>
          <PostCard
            onThreeDotClick={this.openThreeDot}
            dataId={p.id}
            header={{ name: p.created_by.name, profile_pic: p.created_by.profile_pic, created_at: `${toTitleCase(p.created_by.location)} | ${p.created_at.replace('about', '')}`, avatarLink: `/user/${p.created_by.id}` }}
            key={p.id}
          >
            <Divider />
            <ProductWrapper>
              <Flex col f={3}>
                <Media>
                <MediaCarousel items={p.images||[]} />
                </Media>
              </Flex>
              <Flex gap="10px" col f={5} style={{padding: '10px 15px 10px', display: 'flex', flexWrap: "wrap",}}>
                <Typography id='product_name' style={{wordBreak: 'break-all', fontSize: '1.5rem', fontWeight: 600}} variant="h4">
                   {p.product_name.length > 35 ? `${p.product_name.substring(0, 35)}...` : p.product_name}
                  </Typography>
                <Flex gap="20px" align="center">
                  <Typography id='product_price' style={{ marginTop: '8px', color: '#1da509', fontWeight: 'bold', fontSize: '18px'}}>${p.price?.usd} {p.price?.inr && ` (₹${p.price?.inr})` || ''}</Typography>
                  <div style={{transform: 'scale(0.7)'}}>
                    <Rating disabled={true} total={5} value={p.average_rating} />
                  </div>
                </Flex>
                <CPWrapper>
                  <div><b>Category:</b> {p.categories.join(', ')}</div>
                  <div><b>Product ID:</b> {p.product_unique_id}</div>
                </CPWrapper>
                <div>
                  <SeeMore limit={100} text={p.description} />
                </div>
                <Flex style={{margin: '15px 0px', width: '350px'}}>
                  {p.coin_discount>0 && <DiscountCard discount={p.coin_discount} coins={p.equivalent_coins} />}
                  </Flex>                
              </Flex>
            </ProductWrapper>
            <Flex px="20px" py="35px" gap="20px">
              <Flex col style={{margin: '0px 0px 20px 0px',width:"35.1%"}}>
                {
                  p.is_rated ? <RatedButton data-test-id="after-rated" disabled data-id={p.id} onClick={this.onRateClick} >Rate this Product</RatedButton> :
                  <RateButton data-test-id="rate-product" data-id={p.id} onClick={this.onRateClick} >Rate this Product</RateButton>
                }
                
                </Flex>
                
              <Flex style={{padding: '13px 0px',marginLeft:"1rem", flexWrap: 'wrap', alignItems:'center'}}>
                  <div id='Website-link' style={{marginRight: '5px'}}>Website Link:</div> 
                    <span style={{paddingLeft: '10px', color: '#48a15b'}}>
                      <a href={p.website_link} target="_blank">
                        <div style={{display:'flex', flexDirection:'column'}}>
                          <img src={LogoHex} width="25px" />
                          <InsertLinkIcon style={{color:'inherit', marginTop:'-16px', marginLeft:'8px'}}/>
                        </div>
                      </a>
                    </span>
                </Flex>
              </Flex>
              <div style={{ margin: "0 0 20 20" ,lineHeight: '30px', display: 'flex', gap: '5px', fontSize: '0.875rem', color: '#212529', paddingRight:'8px' }}>
                        <img src={iconGreenThumb} width="30" />
                        <span id='total-likes'>{p.total_likes}</span>
                        <img src={commentCount} width="30" />
                        <span id='total-comments'>{p.total_comments}</span>
                      </div>
            <Divider data-test-id="divider"/>
            <CommonActions 
              onStarClick={this.handleFavClick(p.id)} 
              isFavourite={p.is_favourite}
              onShareClick={this.handleShareClick(p)}
              onCommentClick={this.goToProduct(p.id)}
              likeButton={
                <LikeAPost 
                  navigation={this.props.navigation} 
                  id="" 
                  likeable_type="BxBlockCfproductlisting11::Product" 
                  isLiked={p.is_liked} 
                  post_id={String(p.id)} 
                  like_id={p.like_id[0]?.id}
                  onLikeChange={this.onLikeChange}
                  onLikeIdUpdate={this.onLikeIdUpdate}
                />} 
            />
          </PostCard>)
        }
        </InfiniteScroll>
      </DashboardLayout>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

CfProductListing11Controller.contextType = UserContext

function toTitleCase(str: string) {
  return str.replace(
    /\w\S*/g,
    function (txt: string) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

const Media = styled('div')({
  // maxWidth: '250px',
  '& > div > div > div > ul > li > img': {
    // maxHeight: 'inherit',
    // marginRight: '100px',
    minHeight: '256px',
    objectFit: 'fill',
  }
})

const StyledPopper = styled(Popper)({
  maxHeight: '80vh',
  overflowY: 'auto',
  overflowX: 'hidden',
  marginLeft:'20px',
  backgroundColor: "white",
  boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px'
});

const CPWrapper = styled(Flex)({
  margin: '15px 0px',
  gap: "20px",
  '@media screen and (max-width: 480px)': {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  }
})

const ProductWrapper = styled('div')({
  color: '#222',
  display: 'flex',
  flexWrap: 'wrap',
  gap: "15px",
  padding: "20px 10px",
  '& .carousel': {
    minWidth: "200px",
  },
  // '@media screen and (max-width: 768px)': {
  //   display: 'flex',
  //   flexWrap: 'wrap',
  //   '& .carousel': {
  //     minWidth: "200px",
  //   }
  // }
  // px="20px" py="10px" gap="15px"
})

const RatedButton = styled('button')({
  background: '#ececec',
  border: '2px solid #e3e3e3',
  borderRadius: '10px',
  color: '#727373',
  padding: '10px 15px',
  marginTop: '0px'
})

const RateButton = styled('button')({
  background: '#2caa38',
  border: '2px solid #e3e3e3',
  borderRadius: '10px',
  color: '#fff',
  padding: '10px 15px',
  marginTop: '0px'
})

const RoundedCard = styled(Card)({
  borderRadius: '15px',
  padding: '10px 20px',
  margin: '20px 0px'
})

const FormWrapper = styled('div')({
  '& > div': {
    alignItems: 'center',
  }
})


const Flex1 = styled('div')({
  flex: 1,
})



// Customizable Area End
