import React from 'react';
import { styled } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import { Box, Typography } from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Avatar from '@material-ui/core/Avatar';

export function SeekingFund({ detail, onModelClick }: any) {
    return <>

        <SectionItem>
            <Card style={{ borderRadius: "10px", border: "1px solid #f2f2f2", height: "350px" }}>
                <CardSection>
                    <DivSection id="ModelClickDiv" data-testId="fundModal" onClick={() => { onModelClick(detail) }}>
                        <DetailSection style={{gap:"10px"}}>
                            <FundSectionLeftText>
                                <SectionItem style={{ fontSize: "20px" }}>

                                    <div style={{ display: "flex", gap: "8px" }}>
                                        <img src={detail?.attributes?.image} alt={detail?.attributes?.project_name?.slice(0, 7)} width="65" height="65" style={{borderRadius:"10px"}} />
                                        {detail?.attributes?.project_name?.length > 12 ? (
                                            <Typography style={{ fontSize: "20px", wordBreak: "break-all" }} variant="subtitle1" component="div">
                                                {detail?.attributes?.project_name?.slice(0, 7)}
                                                <NormalText onClick={() => onModelClick(detail)}> see more...</NormalText>
                                            </Typography>
                                        ) : (
                                            <Typography style={{ fontSize: "20px", wordBreak: "break-all" }} variant="subtitle1" component="div">
                                                {detail?.attributes?.project_name}
                                            </Typography>
                                        )
                                        }
                                    </div>

                                </SectionItem>
                            </FundSectionLeftText>
                            <FundSectionRightText>
                                <SectionItem style={{display:"flex", justifyContent:"end"}}>
                                    {detail?.attributes?.project_status !== 'inactive' ? <LiveBtn>
                                        {detail?.attributes?.project_status}
                                    </LiveBtn> : <InactiveBtn>{detail?.attributes?.project_status}</InactiveBtn>}

                                </SectionItem>
                            </FundSectionRightText>
                        </DetailSection>
                        <DetailSection>
                            <FundSectionLeftText>
                                <Typography variant="subtitle1" component="div">Project ID</Typography>
                                <Typography variant="subtitle2" component="div">{detail?.attributes?.project_unique_id}</Typography>
                            </FundSectionLeftText>
                            <FundSectionRightText>
                                <Typography variant="subtitle1" component="div">Location</Typography>
                                <Typography variant="subtitle2" component="div">{detail?.attributes?.location}</Typography>
                            </FundSectionRightText>
                        </DetailSection>
                        <DetailSection style={{ fontWeight: 'normal' }}>
                            <FundSectionLeftText>
                                <Typography variant="subtitle1" component="div">Organization Name</Typography>
                                <Typography variant="subtitle2" component="div">{detail?.attributes?.organization_name}</Typography>
                            </FundSectionLeftText>
                            <FundSectionRightText>
                                <Typography variant="subtitle1" component="div">Preferences</Typography>
                                {detail?.attributes?.preferences?.map((preference: any)=> <Typography variant="subtitle2" component="div">{preference}</Typography>)}
                                
                            </FundSectionRightText>
                        </DetailSection>
                        <DetailSection style={{ fontWeight: 'normal', marginBottom:"15px" }}>
                            <FundSectionLeftText>
                                <Typography variant="subtitle1" component="div">Funding Required</Typography>
                                <Typography variant="subtitle2" component="div">${detail?.attributes?.funding_amount}</Typography>
                            </FundSectionLeftText>
                            <FundSectionRightText>
                                <Typography variant="subtitle1" component="div">SDG Focus</Typography>
                                <AvatarGroup spacing='small'>
                                {detail?.attributes?.images?.map((sdgImageURL: any, index: any) => <Avatar style={{marginRight:"5px",height:"25px",width:"25px",border:"3px solid rgb(255, 255, 255)"}} src={sdgImageURL.length > 0 && sdgImageURL[0]?.image_url} alt={detail?.attributes?.goals_sdg[index]} />)}
                                </AvatarGroup>
                            </FundSectionRightText>

                        </DetailSection>

                    </DivSection>
                </CardSection>
            </Card>
        </SectionItem>

    </>
}

const SectionItem = styled(Box)({
    margin: '8px 0px',
    '& .MuiTypography-root.MuiTypography-subtitle1': {
        fontWeight: 'bold',
        fontSize: 11,
    },
    '& .MuiTypography-root.MuiTypography-subtitle2': {
        fontWeight: 'normal',
        color: 'grey',
        fontSize: 14
    }
});

const CardSection = styled('div')({
    padding: "15px",
    width: "300px"
});

const DivSection = styled('div')({
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
});

const DetailSection = styled('div')({
    display: "flex",
    justifyContent: "space-between"
});

const NormalText = styled('span')({
    fontWeight: "normal",
    color: "#685b5b",
    fontSize: "15px",
    fontweight: "bold"
});

const LiveBtn = styled(Typography)({
    background: '#E4EBF5',
    border: '1px solid #E5EDE6',
    borderRadius: '10px',
    color: '#2175E7',
    padding: '8px 25px',
    fontSize: '14px',
    width: 'max-content',
    fontWeight: 'bold',
    mixBlendMode: 'normal',
    textTransform: 'capitalize'
});

const InactiveBtn = styled(Typography)({
    background: '#E5E5E5',
    border: "1px solid #E5EDE6",
    borderRadius: '10px',
    color: '#787878',
    padding: '10px 8px',
    fontSize: '13px',
    width: 'max-content',
    fontWeight: 'bold',
    mixBlendMode: 'normal',
    textTransform: 'capitalize'
});

const FundSectionLeftText = styled(Box)({
    margin: "4px 0px",
    color: '#464646',
    width: '60% !important',

    '& .MuiTypography-root.MuiTypography-subtitle1': {
        fontWeight: "bold",
        fontSize: 14,
    },
    '& .MuiTypography-root.MuiTypography-subtitle2': {
        fontWeight: "normal",
        color: "grey",
        fontSize: 14,
    }
})

const FundSectionRightText = styled(Box)({
    margin: "4px 0px",
    color: '#464646',
    width: '40% !important',

    '& .MuiTypography-root.MuiTypography-subtitle1': {
        fontWeight: "bold",
        fontSize: 14,
    },
    '& .MuiTypography-root.MuiTypography-subtitle2': {
        fontWeight: "normal",
        color: "grey",
        fontSize: 14,
    }
})